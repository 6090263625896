import ApiConstants from '../Constants/ApiConstants';
import { DefaultAccountDTO } from '../Models/Common';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface AccountAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: string;
  AccountGroup?: string;
  AccountGroupsString?: string;
  includeBalance?: string;
  accountNature?: string[];
}

const defaultConfig: AccountAPIConfig = {
  SearchTerm: '',
  Limit: 10,
  Page: 0
};

class AccountsService {
  static apiConfig: AccountAPIConfig;

  static getAccountEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&includeBalance=${this.apiConfig.includeBalance?.trim() || ''}&page=${
      this.apiConfig.Page
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.AccountGroupsString
        ? '&accountGroups=' + this.apiConfig.AccountGroupsString
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS + queryString;
    return finalEndpoint;
  }
  static getRecentUsedAccountsEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&includeBalance=${this.apiConfig.includeBalance?.trim() || ''}&page=${
      this.apiConfig.Page
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.AccountGroupsString
        ? '&accountGroups=' + this.apiConfig.AccountGroupsString
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS + queryString + "&sortCriteria=RECENT_USED_ACCOUNTS";
    return finalEndpoint;
  }
  static getOBAccountByPage() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?query=&search=${this.apiConfig.SearchTerm}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    const finalEndpoint: string =
      ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS + queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchAccountsList() {
    const finalEndpoint: string =
      ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS +
      '?query=search=&limit=10000&status=ACTIVE';
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchAccountsByCodes(codes: string[]) {
    return http
      .post(`${ApiConstants.URL.ACCOUNTS.FETCH_BY_CODES}`, codes)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  /**
   *
   * @param accountCodes -array of account codes
   * @returns - promise returning account details for given account codes
   */
  static fetchAccountsByAccountCode = (
    accountCodes: string[] = []
  ): Promise<any> => {
    return http.post(
      `${ApiConstants.URL.INDIA_TDS.ACCOUNT_CODES}`,
      accountCodes
    );
  };
  static deleteAccount(account: any) {
    return http2
      .delete(ApiConstants.URL.ACCOUNTS.DELETE_ACCOUNT(account.id))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getFeeAccounts() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const url = ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS;
    const queryString = `?search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}&accountGroup.name=${this.apiConfig.AccountGroup}`;
    const finalEndPoint = url + queryString;

    return http.get(finalEndPoint).catch((err: any) => {
      console.error('Error loading Fee Account: ', err);
      return Promise.reject(err);
    });
  }

  static getAccountbyCode(accCode: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const url = ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS;
    const queryString = `?query=status=ACTIVE,code=${accCode}`;
    const finalEndPoint = url + queryString;

    return http.get(finalEndPoint).catch((err: any) => {
      console.error('Error loading Fee Account: ', err);
      return Promise.reject(err);
    });
  }

  static addOpeningBalance(data: any) {
    const finalString: string =
      ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS +
      ApiConstants.URL.ACCOUNTS.ADD_OPENING_BALANCE;
    return http
      .patch(finalString, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchHSNCodes(searchTerm?:any) {
    let finalEndPoint = ApiConstants.URL.ACCOUNTS.HSN;

    if (!Utility.isEmpty(searchTerm)) {
      finalEndPoint = finalEndPoint + `?search=${searchTerm}`;
    }

    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchSACCodes(searchTerm?:any) {
    let finalEndPoint = ApiConstants.URL.ACCOUNTS.SAC;

    if (!Utility.isEmpty(searchTerm)) {
      finalEndPoint = finalEndPoint + `?search=${searchTerm}`;
    }

    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchNatureOfIncomePayments() {
    const finalEndPoint =
      ApiConstants.URL.ACCOUNTS.NATURE_OF_INCOME_PAYMENT + '?&limit=500';
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchUOMSchemes(id: string) {
    const finalEndPoint = ApiConstants.URL.ACCOUNTS.UOM_SCHEMES + id;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchAccountGroup(
    currencyCode: string,
    payload = ['Bank', 'Cash', 'Current Assets', 'Current Liabilities']
  ) {
    return http
      .post(
        `${ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNT_GROUPS(currencyCode)}`,
        payload
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchAccountGroupByRecentUsed() {
    return http
      .get(
        `${AccountsService.getRecentUsedAccountsEndPoint()}`
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchAccountNature() {
    const finalEndPoint = ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNT_NATURE;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getNatureOfIncomeUrl(config: AccountAPIConfig) {
    const { BASE, ACCOUNTS } = ApiConstants.URL;
    const limit = config.Limit || 10;
    const searchTerm = config.SearchTerm || '';
    const query = config.Query || '';
    const queryParams = `?limit=${limit}&search=${searchTerm}&query=${query}`;
    const url = `${BASE}${ACCOUNTS.NATURE_OF_INCOME_PAYMENT}${queryParams}`;
    return url;
  }

  static getAccountsWithNature() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&includeBalance=${this.apiConfig.includeBalance?.trim() || ''}&page=${
      this.apiConfig.Page
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.AccountGroupsString
        ? '&accountGroups=' + this.apiConfig.AccountGroupsString
        : ''
    }${
      this.apiConfig.accountNature
        ? '&accountNature=' + this.apiConfig.accountNature
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.ACCOUNTS.FETCH_BY_NATURE + queryString;
    return finalEndpoint;
  }

  static fetchDefaultAccounts() {
    return http.get(`${ApiConstants.URL.ACCOUNTS.DEFAULT_ACCOUNTS}`).then(
      (response: any) => {
        return Promise.resolve(response);
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }

  static updateDefaultAccount(data: DefaultAccountDTO) {
    return http
      .post(`${ApiConstants.URL.ACCOUNTS.DEFAULT_ACCOUNTS}`, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      );
  }
}

export default AccountsService;
