import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKListPicker,
  DKSpinner,
  DKTooltipWrapper,
  INPUT_TYPE,
  showToast,
  TOAST_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import {
  API_STATUS,
  APPROVAL_STATUS,
  APPROVAL_STATUS_LIST,
  BOOKS_DATE_FORMAT,
  COLUMN_TYPE,
  COUNTRY_CODES,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  DOC_TYPE,
  EXPORT_FILE_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  IMPORT_CONSTANTS,
  LABELS,
  MODULES_NAME,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  MODULE_TYPE,
  OPENING_DOCUMENT_LABEL,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  RECURRING_DOCUMENT_TYPE,
  TemplateMappingEvent,
  TEMPLATE_CATEGORY,
  TAX_SYSTEM,
  MOBILE_APP_ACTIONS,
  UOM_NA_ID,
  PRODUCT_TYPE,
  STATUS_TYPE,
  CRM_APP_NAME
} from '../../Constants/Constant';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES,
  TABLES
} from '../../Constants/TableConstants';
import {
  updateColumnInfo,
  updateColumnShift
} from '../../Helper/TableColumnUpdateHelper';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { DraftTypes } from '../../Models/Drafts';
import {
  BtnType,
  CallBackPayloadType,
  IState,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { ColumnDeleteType, IColumn, RowUpdateType } from '../../Models/Table';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  bulkDeleteDrafts,
  createBlankDraft,
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDrafts,
  selectDraftsColumnConfig,
  selectQuoteDraftsData,
  setDraftCenter,
  setPopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import {
  addQuoteColumnConfig,
  fetchQuotes,
  selectQuotes,
  selectQuotesColumnConfig,
  selectQuotesColumnConfigTableId,
  selectQuotesLoadingStatus,
  updateColumnConfig,
  updateQuoteDetails
} from '../../Redux/Slices/QuotesSlice';
import CustomFieldService from '../../Services/CustomField';
import QuotationService, {
  defaultConfig,
  QuotationAPIConfig
} from '../../Services/Quotation';
import DataGridHolder from '../../SharedComponents/DataGridHolder';
import Fulfillment from '../../SharedComponents/FulfillmentPopup/Fulfillment';
import FulfillmentConfirmation from '../../SharedComponents/FulfillmentPopup/FulfillmentConfirmationPopup';
import ApprovalOrReject from '../../SharedComponents/FulfillmentPopup/ApprovalOrRejectPopup';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import Utility, {
  deepClone,
  findApprovalLevels,
  getApprovalLevelAndLevelLabel,
  getCapitalized,
  getUserNameById
} from '../../Utility/Utility';
import NewQuote from './NewQuote';

import { useTranslation } from 'react-i18next';
import SellConfig, { SELL_CONFIG_BTN } from '../../Configs/SellConfig';
import ApiConstants from '../../Constants/ApiConstants';
import {
  DOC_URL_ACTION,
  EXPORTED_FILES_TYPE_EXTENSION,
  RETURN_MODE
} from '../../Constants/Enum';
import useConfirm from '../../Hooks/useConfirm';
import useDebounce from '../../Hooks/useDebounce';
import { QuoteInitialState } from '../../Models/Quote';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import {
  SelectEmailTriggerIdsData,
  selectCurrencyListWithExchangeRate,
  selectCustomFields,
  selectedFooterAction,
  setEmailTriggerIds,
  setFooterAction
} from '../../Redux/Slices/CommonDataSlice';
import DateFormatService from '../../Services/DateFormat';
import DraftService from '../../Services/Drafts';
import EximService from '../../Services/Exim';
import FulfillmentService from '../../Services/FulfillmentService';
import PrintService from '../../Services/Print';
import SalesReturnService from '../../Services/SalesReturn';
import {
  FILTER_DATE,
  SessionStorageService,
  SHOW_TOTAL_IN_BASE_CURRENCY
} from '../../Services/SessionStorageService';
import { Attachments } from '../../SharedComponents/Attachments';
import { ActionMenuEvent } from '../../SharedComponents/DocumentForm/DocumentActionMenu';
import {
  getBulkPrintPreview,
  getEmailPopup,
  getPrintPreview,
  isSalesOrderVisible,
  getMinimizedDocInfo,
  getApprovalLevel,
  getTenantTaxSystem,
  getEmailStatusForGrid,
  addressRenderer,
  checkMultiApprovalRequired
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import BulkFulillment from '../../SharedComponents/FulfillmentPopup/BulkFulfillment';
import DropshipWarning from '../../SharedComponents/FulfillmentPopup/DropshipWarningPopup';
import FulfillmentRecords from '../../SharedComponents/FulfillmentPopup/FulfillmentRecords';
import RecurringDocument from '../../SharedComponents/RecurringDocumentPopup/RecurringDocument';
import SalesReturnPopup from '../../SharedComponents/SalesReturn/SalesReturn';
import SalesReturnRecords from '../../SharedComponents/SalesReturn/SalesReturnRecords';
import CreateAndEditNotes from '../Accounting/CreateAndEditNotes/CreateAndEditNotes';
import { getUpdatedQuoteObject, quoteEmailTemplateConfig } from './QuoteHelper';
import QuoteToInvoicePopup from './QuoteToInvoicePopup';
import {
  isMobileWebView,
  isTabletView,
  isViewportLarge
} from '../../Utility/ViewportSizeUtils';

import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import {
  quotesFilter,
  selectApplySalesPersonFilter,
  selectQuotesFilter
} from '../../Redux/Slices/BookFilterSlice';
import ExpenseDepositForm from '../Bank/ExpenseDeposit/ExpenseDepositForm';
import QuoteToSalesOrderPopup from './QuoteToSalesOrderPopup';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { useHistory, useParams } from 'react-router-dom';
import AppManager from '../../Managers/AppManager';
import { ResponsiveDateRangePicker } from '../responsive/ResponsiveDateRangePicker';
import { localizedText } from '../../Services/Localization/Localization';
import AuthService from '../../Services/Auth';
import AutomationService from '../../Services/Automation';
import ApprovalHistory from '../../SharedComponents/ApprovalHistory';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import WhatsAppHelper from '../../Helper/WhatsAppHelper';
import ic_whatsapp from '../../Assets/Icons/ic_whatsapp.png';
import DetailsOpener from '../Common/DetailsOpener';
import RateAnalysisForm from '../../SharedComponents/DocumentForm/RateAnalysisForm';
import { CONTACT_LEFT_PANEL_WIDTH } from '../Contacts/Details';
import { COLUMN_VISIBILITY_BUTTON_CONFIG } from '../../SharedComponents/GridColumnVisibility';
import { MainDocumentGridSettings } from '../../SharedComponents/MainDocumentGridSettings';
import { showAddCustomField } from '../../SharedComponents/CustomField/AddCustomField';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import { getUpdatedPurchaseOrderObject } from '../PurchaseOrders/PurchaseOrderHelper';
import RateAnalysisService from '../../Services/RateAnalysis';
import NumberFormatService from '../../Services/NumberFormat';
import { selectDimensions } from '../../Redux/Slices/LocationSlice';
import { fetchContacts } from '../../Redux/Slices/ContactsSlice';
import { COUNTRIES_WITH_CURRENCIES } from '../PriceBook/CountriesWithCurrencies';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import DropshipConfirmation from '../../SharedComponents/FulfillmentPopup/DropshipConfirmationPopup';

export interface IQuotationsProps {
  listByContact?: boolean;
  contact?: any;
}
export default function Quotations(props: IQuotationsProps) {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const initialState: IState = {
    columnData: [],
    rowData: [],
    originalData: [],
    filter: []
  };

  const popupBtnConfig: BtnType[] = [
    {
      title: t(`QUOTES.UPDATE_QUOTE.BUTTON.CANCEL`),
      class: 'bg-gray2 border-m mr-r',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`QUOTES.UPDATE_QUOTE.BUTTON.UPDATE`),
      class: 'bg-button text-white mr-r',
      clickAction: POPUP_CLICK_TYPE.UPDATE_QUOTE
    }
  ];

  const fulfillmentConfirmationPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.FULLFILLMENT_RECORDS.BUTTON.CLOSE`),
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const fulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`QUOTES.DIRECT_FULFILLMENT_OF_QUOTATION.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`QUOTES.DIRECT_FULFILLMENT_OF_QUOTATION.BUTTON.FULFILL`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.FULFILLMENT
    }
  ];

  const fulfillmentRecordsBtnConfig: BtnType[] = [
    {
      title: t(`QUOTES.FULLFILLMENT_RECORDS.BUTTON.CLOSE`),
      class: 'bg-app text-white mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const dropShipButtonConfig: BtnType[] = [
    {
      title: t(`QUOTES.DROPSHIP.BUTTON.NO`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`QUOTES.DROPSHIP.BUTTON.YES`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CONFIRM_DROPSHIP
    }
  ];

  const bulkFulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`QUOTES.BULK_FULFILLMENT_OF_QUOTATION.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`QUOTES.BULK_FULFILLMENT_OF_QUOTATION.BUTTON.FULFILL`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.BULK_FULFILLMENT
    }
  ];

  const convertPopupBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];
  const salesReturnPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Return',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.SALES_RETURN
    }
  ];
  const directExpenseBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_EXPENSE_DEPOSIT
    }
  ];
  const CNBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'bg-gray2 border-m mr-r',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_CREDIT
    }
  ];

  const dispatch = useAppDispatch();

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateQuote: 'click' }
  };

  // State hooks
  const [quotes, setQuotes] = useState(initialState);
  const dimensionData = useAppSelector(selectDimensions);
  const [updating, setUpdating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [editableQuote, setEditableQuote] = useState<any>();
  const [showExportOptions, setShowExportOptions] = useState<boolean>(false);
  const [showFulfillmentPopup, setShowFulfillmentPopup] =
    useState<boolean>(false);
  const [fulfillmentType, setFulfillmentType] = useState<any>(
    'Fulfillment Records'
  );
  const [
    showFulfillmentConfirmationPopup,
    setShowFulfillmentConfirmationPopup
  ] = useState<boolean>(false);
  const [showConversionPopup, setShowConversionPopup] =
    useState<boolean>(false);
  const [showSOConversionPopup, setShowSOConversionPopup] =
    useState<boolean>(false);
  const addQuoteRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>();
  const [showFulfillmentRecordsPopup, setShowFulfillmentRecordsPopup] =
    useState<boolean>(false);
  const [showDropShipWarningPopup, setShowDropShipWarningPopup] =
    useState<boolean>(false);
  const [showBulkFulfillmentPopup, setShowBulkFulfillmentPopup] =
    useState<boolean>(false);
  const [bulkFulfillmentDetails, setBulkFulfillmentDetails] = useState<any[]>(
    []
  );
  const [showRateAnalysisForm, setShowRateAnalysisForm] = useState(false);
  const [rateAnalysisData, setRateAnalysisData] = useState<any>(null);
  const [filters, setFilters] = useState<any>([]);
  const [currentSelectedRows, setCurrentSelectedRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showRecurringPopup, setShowRecurringPopup] = useState<boolean>(false);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [showTotalInBaseCurrency, setShowTotalInBaseCurrency] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const [filterDates, setFilterDates] = useState<any>(undefined);
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [bulkFulfillmentRows, setBulkFulfillmentRows] = useState<any[]>([]);
  const [bulkApprovalRows, setBulkApprovalRows] = useState<any[]>([]);
  const [bulkRejectRows, setBulkRejectRows] = useState<any[]>([]);

  const isApprovalInprogress = useRef<boolean>(false);

  // Store data selectors
  const quotesData = useAppSelector(selectQuotes);
  const loadingStatus = useAppSelector(selectQuotesLoadingStatus);
  const columnConfig = useAppSelector(selectQuotesColumnConfig);
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const columnConfigTableId = useAppSelector(selectQuotesColumnConfigTableId);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const quotesDraftData = useAppSelector(selectQuoteDraftsData) as any[];
  const activeDrafts: any[] = useAppSelector(selectDrafts);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const footerAction = useAppSelector(selectedFooterAction);
  const [showSalesReturnPopup, setShowSalesReturnPopup] =
    useState<boolean>(false);
  const [showSalesReturnRecordsPopup, setShowSalesReturnRecordsPopup] =
    useState<boolean>(false);
  const [salesReturnsDetails, setSalesReturnsDetails] = useState<any>();
  const [showApprovalHistoryPopup, setShowApprovalHistoryPopup] =
    useState<boolean>(false);
  const [approvalHistoryData, setApprovalHistoryData] = useState<any>();
  const [showExpenseButton, setshowExpenseButton] = useState<any>();
  const [expenseData, setExpenseData] = useState<any>();
  const expenseRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [bulkPrintRecordData, setBulkPrintRecordData] = useState<any[]>([]);
  const [showCNAndDNInfo, setShowCNAndDNInfo] = useState({
    showPopup: false,
    data: null,
    documentType: DOC_TYPE.CREDIT_NOTE
  });
  const [isFulfillmentForDropship, setisFulfillmentForDropship] =
    useState<boolean>(false);
  const [showDropshipConfirmationPopup, setShowDropshipConfirmationPopup] =
    useState<boolean>(false);

  // Approval/Reject PopUp

  const [showApprovalOrRejectPopup, setShowApprovalOrRejectPopup] =
    useState<boolean>(false);

  const [approvalOrRejectData, setApprovalOrRejectData] = useState<any>({
    title: '',
    actionDetails: null
  });

  const [detailsPopupData, setDetailsPopupData] = useState<any>();

  const createCreditRef = useRef<UpdateCallBacksRefType>(refInitialState);
  let history = useHistory();
  const availableCustomFields = useAppSelector(selectCustomFields);
  const emailTriggerIds = useAppSelector(SelectEmailTriggerIdsData);

  // Filter contacts
  const quotesFilterData: any = useAppSelector(selectQuotesFilter);
  const {
    id: docCodeInURL,
    contactId: contactIdInURL,
    action: actionInURL
  }: any = useParams();

  const isApprovalLockEnabled =
    tenantInfo?.additionalSettings?.QUOTE_APPROVAL_LOCK_ENABED;

  const isQuoteEditBlockEnabledForMultiUser =
    tenantInfo?.additionalSettings?.QUOTE_EDIT_BlOCK_MULTI_USER;

  const canApplySalesPersonFilterOnQuotes = useAppSelector(
    selectApplySalesPersonFilter
  );

  useEffect(() => {
    if (!props.listByContact) {
      RouteManager.setPresenter({ props });
    }
    loadApprovalConditionList();
    if (!props.listByContact) {
      dispatch(
        fetchDrafts({
          tableId: draftsTableId,
          isSaveColumnId: isSavedColumnId,
          draftTypeColId: draftTypeColId,
          draftTypeColValue: LABELS.QUOTES
        })
      );
    }
    if (!Utility.isEmpty(tenantInfo)) {
      setInitialConfigFromStorage();
    }
    return () => setQuotes(initialState);
  }, []);

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  // Open doc from document code in the URL
  useEffect(() => {
    if (!props.listByContact) {
      const openedAndMaximizedDrafts = activeDrafts?.filter(
        (draft: any) => draft.isMaximized && draft.isCenterAlign
      );
      if (!Utility.isEmpty(docCodeInURL)) {
        directOpenDocument(docCodeInURL);
      } else {
        openedAndMaximizedDrafts.forEach((draft: any) => {
          dispatch(removeDraft(draft.id));
        });
      }
    }
  }, [docCodeInURL]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo) && Utility.isEmpty(filterDates)) {
      setInitialConfigFromStorage();
    }
  }, [tenantInfo]);

  const setInitialConfigFromStorage = () => {
    const savedSettings = SessionStorageService.fetchConfigSettings(
      MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
      DOC_TYPE.QUOTE
    );
    if (!Utility.isEmpty(savedSettings)) {
      setShowTotalInBaseCurrency(savedSettings[SHOW_TOTAL_IN_BASE_CURRENCY]);
    }
    setFilterDates({
      startDate: DateFormatService.getDateStrFromDate(
        Utility.isObject(quotesFilterData?.filterDates)
          ? DateFormatService.getDateFromStr(
              quotesFilterData?.filterDates.startDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          : Utility.getFinancialStartDate(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      endDate: DateFormatService.getDateStrFromDate(
        Utility.isObject(quotesFilterData?.filterDates)
          ? DateFormatService.getDateFromStr(
              quotesFilterData?.filterDates.endDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          : Utility.getFinancialEndDate(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      )
    });
  };

  useEffect(() => {
    if (!Utility.isEmpty(filterDates)) {
      let config = {
        ...defaultConfig,
        Query: `documentDate>=${filterDates.startDate},documentDate<=${filterDates.endDate}`,
        QueryParam: `&sort=documentDate&sortDir=DESC`
      };

      if (
        canApplySalesPersonFilterOnQuotes.canApplyFilter &&
        canApplySalesPersonFilterOnQuotes?.loggedInUserInfo?.[0]?.id
      ) {
        config.SalesPerson =
          canApplySalesPersonFilterOnQuotes?.loggedInUserInfo?.[0]?.id;
      }
      QuotationService.apiConfig = config;

      // Check selected filter already exist
      if (Utility.isObject(quotesFilterData?.config)) {
        let filteredQueryList = Utility.getFilterParameters(
          quotesFilterData?.queries,
          REMOTE_CONFIG_TABLES.QUOTATION
        );

        const list = Utility.getFilteredQueryList(filteredQueryList);
        let withoutFilter, withFilter;
        if (
          list.newFilteredQueyListArr &&
          list.newFilteredQueyListArr.length > 0
        ) {
          withoutFilter = Utility.getQueryString(list.newFilteredQueyListArr);
        }
        if (list.customField && list.customField.length > 0) {
          assignProductModule('CLASS', 'CLASS', list?.customField);
          assignProductModule('CLASS', 'LOCATION', list?.customField);
          withFilter = Utility.getQueryString(list.customField);
        }

        config = {
          ...config,
          SearchTerm: quotesFilterData?.SearchTerm,
          Query:
            `documentDate>=${filterDates.startDate},documentDate<=${filterDates.endDate}` +
            (withoutFilter ? `,${withoutFilter}` : ''),
          QueryParam: `&sort=documentDate&sortDir=DESC&${withFilter}`
        };
        QuotationService.apiConfig = config;

        setFilterDataInStore(
          { ...config },
          quotesFilterData?.SearchTerm,
          deepClone(quotesFilterData?.queries),
          {
            startDate: filterDates.startDate,
            endDate: filterDates.endDate
          }
        );
      } else {
        setFilterDataInStore(
          { ...config },
          quotesFilterData?.SearchTerm,
          deepClone(quotesFilterData?.queries),
          { startDate: filterDates.startDate, endDate: filterDates.endDate }
        );
      }
    }
  }, [filterDates]);

  useEffect(() => {
    if (!Utility.isEmpty(filterDates)) {
      getInitialFilterData();
    }
  }, [quotesFilterData]);

  // Executes when quote data changes in store
  useEffect(() => {
    if (!Utility.isEmpty(quotesData)) {
      const payload: any = Utility.getKeyOfColumn(
        draftsColumnConfig,
        COLUMN_CODE.DRAFTS.Payload
      );
      const appNameColId: any = Utility.getKeyOfColumn(
        draftsColumnConfig,
        COLUMN_CODE.DRAFTS.AppName
      )?.[0]?.id;
      const payloadKey = payload && payload.length ? payload[0].id : null;
      let draftQuotes;

      if (payloadKey) {
        const userMap = Utility.getUserIdMap();
        draftQuotes = quotesDraftData
          ?.filter((data) => {
            if (appNameColId && data.cells[appNameColId] === CRM_APP_NAME) {
              return false;
            }

            return true;
          })
          ?.map((data: any) => {
            if (data.cells[payloadKey]) {
              return {
                ...Utility.parseDraftDocumentPayload(data.cells[payloadKey]),
                id: data._id,
                isDraftDocument: true,
                approvalStatus: data.cells.approvalStatus,
                draftCode: data.cells.documentSequenceCode,
                createdBy: userMap.get(data.createdBy)
              };
            }
          });

        if (draftQuotes) {
          draftQuotes = draftQuotes?.filter((ele: any) => {
            if (!Utility.isEmpty(searchTerm)) {
              return (
                ele.approvalStatus !== APPROVAL_STATUS.APPROVED &&
                ele.draftCode?.search(new RegExp(searchTerm, 'i')) >= 0
              );
            } else {
              return ele.approvalStatus !== APPROVAL_STATUS.APPROVED;
            }
          });
        }
      }

      const { content, pageable, totalPages } = quotesData;

      let combinedQuotes = [...content];
      if (
        draftQuotes &&
        draftQuotes.length > 0 &&
        Utility.isEmpty(filters) &&
        !props.listByContact
      ) {
        combinedQuotes = [...draftQuotes, ...combinedQuotes];
      }

      /***********************************
       * Common updates on column config *
       ***********************************/

      let tempColumnConfig = deepClone(columnConfig) as any[];
      tempColumnConfig =
        Utility.updateLocationAndClassTitleForGrid(tempColumnConfig);
      if (tenantInfo.country === COUNTRY_CODES.IN) {
        tempColumnConfig = localiseColumnConfig(tempColumnConfig);
      }
      // Convert user type to select type to support filter
      tempColumnConfig = Utility.convertUserTypeColumnToSelectType([
        ...tempColumnConfig
      ]);

      if (!Utility.isApprovalColumn(DOC_TYPE.QUOTE)) {
        tempColumnConfig = tempColumnConfig.filter(
          (config) => config.columnCode !== COLUMN_CODE.QUOTE.ApprovalStatus
        );
      }

      let documentDateColumnIndex = tempColumnConfig.findIndex(
        (config) => config.columnCode === COLUMN_CODE.QUOTE.DocumentDate
      );
      if (documentDateColumnIndex !== -1) {
        tempColumnConfig[documentDateColumnIndex] = {
          ...tempColumnConfig[documentDateColumnIndex],
          name: `${Utility.convertInTitleCase(localizedText('quote'))} Date`
        };
      }

      if (!Utility.isEmpty(combinedQuotes) || (filters && filters.length > 0)) {
        // Remove fulfillment column when sales order is enabled
        if (isSalesOrderVisible()) {
          let confToEditIndex = tempColumnConfig.findIndex(
            (config) =>
              config.columnCode === COLUMN_CODE.QUOTE.FulfillmentStatus
          );
          if (confToEditIndex !== -1) {
            tempColumnConfig.splice(confToEditIndex, 1);
          }
        }

        let configToEditIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'contact'
        );
        if (configToEditIndex !== -1) {
          tempColumnConfig[configToEditIndex] = {
            ...tempColumnConfig[configToEditIndex],
            renderer: (obj: any) => {
              return (
                <div className="row justify-content-between">
                  <div
                    onClick={() => {
                      contactDetailOpener(
                        obj?.rowData?.contactDto?.code ||
                          obj?.rowData?.contactCode
                      );
                    }}
                  >
                    <DKLabel
                      text={obj.value}
                      className="underline cursor-hand fs-m mr-r"
                    />
                  </div>
                  <div className="row width-auto">
                    {getEmailStatusForGrid(obj.rowData, DOC_TYPE.QUOTE)}
                    <DKLabel text={Utility.getIconForGrid(obj.rowData)} />
                    {(obj.rowData.linkedSalesInvoices?.length > 0 ||
                      obj.rowData.linkedSalesOrders?.length > 0 ||
                      obj.rowData.linkedDocuments?.length > 0) && (
                      <DKTooltipWrapper
                        contentRenderer={() => getTootltipContent(obj.rowData)}
                        tooltipClassName="bg-deskera-secondary width-auto"
                      >
                        <DKIcon
                          src={DKIcons.data_type.ic_url}
                          className="ic-xs opacity-60 cursor-pointer ml-s"
                        />
                      </DKTooltipWrapper>
                    )}
                  </div>
                </div>
              );
            }
          };
        }
        if (!!props?.listByContact && configToEditIndex !== -1) {
          tempColumnConfig.splice(configToEditIndex, 1);
        }

        let statusColumnIndex = tempColumnConfig.findIndex((column: any) => {
          return column.columnCode === COLUMN_CODE.QUOTE.Status;
        });
        if (statusColumnIndex !== -1) {
          tempColumnConfig[statusColumnIndex] = {
            ...tempColumnConfig[statusColumnIndex],
            allowFilter: true
          };
        }

        let dueDateConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.QUOTE.DueDate
        );
        if (dueDateConfigIndex !== -1) {
          tempColumnConfig[dueDateConfigIndex] = {
            ...tempColumnConfig[dueDateConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              const infoObj = Utility.getDueDateInfoForGrid(
                obj.value,
                obj.rowData.isDraftDocument
              );
              const status = obj.rowData?.status
                ? obj.rowData?.status[0]
                : null;
              return (
                <div className="column">
                  {status && status === DOCUMENT_STATUS.PROCESSED && (
                    <DKLabel
                      text={DateFormatService.getDateStrFromDate(obj.value)}
                      className={`${infoObj.class}`}
                    />
                  )}
                  {status && status !== DOCUMENT_STATUS.PROCESSED && (
                    <DKTooltipWrapper
                      content={infoObj.text}
                      tooltipClassName="bg-deskera-secondary width-auto"
                    >
                      <DKLabel
                        text={DateFormatService.getDateStrFromDate(obj.value)}
                        className={`${infoObj.class}`}
                      />
                    </DKTooltipWrapper>
                  )}
                </div>
              );
            }
          };
        }

        let totalAmountColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.QUOTE.TotalAmount
        );

        if (totalAmountColumnIndex !== -1) {
          let name = tempColumnConfig[totalAmountColumnIndex].name as string;
          name = name.split('(')[0];
          tempColumnConfig[totalAmountColumnIndex] = {
            ...tempColumnConfig[totalAmountColumnIndex],
            allowFilter: false,
            name: showTotalInBaseCurrency
              ? `${name}(${tenantInfo.currency})`
              : name
          };
        }

        let attachmentColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'attachments'
        );

        if (attachmentColumnIndex !== -1) {
          tempColumnConfig[attachmentColumnIndex] = {
            ...tempColumnConfig[attachmentColumnIndex],
            renderer: (obj: any) => {
              if (obj?.rowData?.attachmentsWithLink?.length) {
                return (
                  <div className="row">
                    <div onClick={() => onAttachmentCLicked(obj)}>
                      <DKIcon
                        src={DKIcons.ic_attachment}
                        className="ic-s cursor-pointer opacity-60 hover:opacity-50"
                      />
                    </div>
                    <DKButton
                      title="View"
                      className="text-blue underline p-v-0"
                      onClick={() => onAttachmentCLicked(obj)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            }
          };
        }

        let remarksConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.QUOTE.Remarks
        );

        if (remarksConfigIndex !== -1) {
          tempColumnConfig[remarksConfigIndex] = {
            ...tempColumnConfig[remarksConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              let remarksData = '';
              if (
                obj.rowData.multiApprovalDetails?.approvalHistory?.length > 0
              ) {
                const allRemarks =
                  obj.rowData.multiApprovalDetails.approvalHistory
                    .filter((history: any) => history?.remarks)
                    .map(
                      (history: any) =>
                        `Level ${history.level}: ${history.remarks}`
                    );

                remarksData = allRemarks.join(', ');
              }
              return (
                <div className="column">
                  <DKTooltipWrapper
                    content={remarksData}
                    tooltipClassName="bg-deskera-secondary width-auto"
                  >
                    <DKLabel text={obj.value} />
                  </DKTooltipWrapper>
                </div>
              );
            }
          };
        }

        let billToConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.BillTo
        );

        let shipToConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.ShipTo
        );

        if (billToConfigIndex !== -1) {
          tempColumnConfig[billToConfigIndex] = {
            ...tempColumnConfig[billToConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              if (!Utility.isEmpty(obj?.rowData?.billTo)) {
                return addressRenderer(obj, COLUMN_CODE.SALES_ORDER.BillTo);
              } else {
                return '';
              }
            }
          };
        }

        if (shipToConfigIndex !== -1) {
          tempColumnConfig[shipToConfigIndex] = {
            ...tempColumnConfig[shipToConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              if (!Utility.isEmpty(obj?.rowData?.shipTo)) {
                return addressRenderer(obj, COLUMN_CODE.SALES_ORDER.ShipTo);
              } else {
                return '';
              }
            }
          };
        }

        let createdByConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.CreatedBy
        );

        if (createdByConfigIndex !== -1) {
          tempColumnConfig[createdByConfigIndex] = {
            ...tempColumnConfig[createdByConfigIndex],
            options: Utility.getUserOptions()
          };
        }

        const rowData = getRowData(combinedQuotes);
        const filterColumns = getColumnsForFilter(tempColumnConfig);
        const passingData = {
          columnData: getFilteredColumns(tempColumnConfig),
          rowData,
          filter: filters,
          originalData: rowData,
          filterColumns: filterColumns
        };
        setQuotes(passingData);
        setTotalPageCount(totalPages);
        setCurrentPage(+pageable?.pageNumber + 1);
      } else {
        const rowData = getRowData(combinedQuotes);

        let configToEditIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'contact'
        );
        if (!!props?.listByContact && configToEditIndex !== -1) {
          tempColumnConfig.splice(configToEditIndex, 1);
        }
        const filterColumns = getColumnsForFilter(tempColumnConfig);
        const passingData = {
          columnData: getFilteredColumns(deepClone(tempColumnConfig)),
          rowData,
          filter: filters,
          originalData: rowData,
          filterColumns: filterColumns
        };
        setQuotes(passingData);
        setTotalPageCount(totalPages);
      }
    }
  }, [
    quotesData,
    quotesDraftData,
    showTotalInBaseCurrency,
    availableCustomFields
  ]);

  const getFilteredColumns = (columns: any[]) => {
    const columnCode: any[] = [];
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.VIEW)) {
      columnCode.push(COLUMN_CODE.QUOTE.Contact);
    }
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.VIEW_PRICE)) {
      columnCode.push(COLUMN_CODE.QUOTE.TotalAmount);
    }
    columnCode.push(COLUMN_CODE.QUOTE.Crm3DealName);
    if (Utility.isNotEmpty(columnCode)) {
      columns = columns?.filter((col) => !columnCode.includes(col.columnCode));
    }
    return columns;
  };

  const contactDetailOpener = (contactId: any) => {
    let documentCode = contactId;
    setDetailsPopupData({
      documentSeqCode: documentCode,
      documentType: DOC_TYPE.Contact,
      showDetailsOpener: true
    });
  };

  const getApprovalOptions = (input: any[]) => {
    const approvalLevels = findApprovalLevels('QUOTATION');
    const options = Object.keys(approvalLevels).map((key) => {
      return {
        id: key,
        name: approvalLevels[key],
        color: 'data-grid-badge-color-10'
      };
    });
    options.forEach((opt: any) => {
      input.push(opt);
    });
    return input;
  };

  const getColumnsForFilter = (columnConfigs: any[]) => {
    let tempColumnConfigList: any[] = columnConfigs;
    tempColumnConfigList?.forEach((col) => {
      switch (col.key) {
        case 'createdBy':
          col.allowFilter = true;
          break;
        case 'purchaseInvoiceType':
          col.allowFilter = true;
          break;
        case 'approvalStatus':
          col.options = getApprovalOptions(col.options);
          break;
      }
    });
    tempColumnConfigList =
      tempColumnConfigList.filter((col) => col.allowFilter !== false) || [];
    const productColumnForFilter = {
      id: 'product',
      key: 'product',
      name: 'Product',
      type: INPUT_TYPE.DROPDOWN,
      formatter: DocumentConfigUtility.formatterDocumentForFilter,
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'z-index-3 shadow-m width-auto',
        searchApiConfig: {
          getUrl: (search: any) =>
            DocumentConfigManager.getProductURLForFilter(search),
          dataParser: (data: any) =>
            DocumentConfigUtility.productDataParser(data, DOC_TYPE.ORDER),
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.rendererForFilter,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    };

    const contactColumnForFilter = {
      id: 'contact',
      key: 'contact',
      name: 'contact',
      type: INPUT_TYPE.DROPDOWN,
      formatter: DocumentConfigUtility.formatterDocumentForFilter,
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'z-index-3 shadow-m width-auto',
        searchApiConfig: {
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 10,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query: 'status=active'
            };
            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.rendererForFilter,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    };
    tempColumnConfigList.push(productColumnForFilter);
    tempColumnConfigList.push(contactColumnForFilter);
    tempColumnConfigList = tempColumnConfigList.map((config: any) => {
      let columnConfig = { ...config };
      if (
        INPUT_TYPE.DROPDOWN === config.type ||
        INPUT_TYPE.SELECT === config.type
      ) {
        columnConfig.filterConditions = [{ title: 'Equal to', value: 'eq' }];
      }
      return columnConfig;
    });
    const columnsToRemove = [COLUMN_CODE.QUOTE.Crm3DealName];
    tempColumnConfigList = tempColumnConfigList.filter(
      (col) => !columnsToRemove.includes(col.columnCode)
    );
    return tempColumnConfigList;
  };

  const removeNonCloumnFields = (queries: any[]) => {
    const nonColumnFields = ['product', 'contact'];
    const filteredQueries = queries.filter(
      (data) => !nonColumnFields.includes(data?.key)
    );

    return filteredQueries;
  };

  const getQueryForNonColumnField = (queryString: string, queries: any[]) => {
    let nonColumnFieldQueries = '';
    nonColumnFieldQueries = queryString ?? '';
    queries.map((query: any) => {
      if (query.key === 'product') {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries +
            `,quotationItemList.productCode=${query?.value?.productId}`
          : `quotationItemList.productCode=${query?.value?.productId}`;
      }
      if (query.key === 'contact') {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries + `,contactCode=${query?.value?.code}`
          : `contactCode=${query?.value?.code}`;
      }
    });

    return nonColumnFieldQueries;
  };

  const localiseColumnConfig = (tempColumnConfig: any[]) => {
    const fulfillmentColumnConfigIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === COLUMN_CODE.QUOTE.FulfillmentStatus
    );
    if (fulfillmentColumnConfigIndex !== -1) {
      tempColumnConfig[fulfillmentColumnConfigIndex] = {
        ...tempColumnConfig[fulfillmentColumnConfigIndex],
        name: 'Delivery Status',
        options: [
          {
            id: 'FULLY_FULFILLED',
            name: 'Delivered',
            color: 'data-grid-badge-color-6'
          },
          {
            id: 'PARTIAL_FULFILLED',
            name: 'Partially Delivered',
            color: 'data-grid-badge-color-5'
          },
          {
            id: 'UNFULFILLED',
            name: 'Not Delivered',
            color: 'data-grid-badge-color-10'
          }
        ]
      };
    }
    return tempColumnConfig;
  };

  const getTooltipContainer = (type: string, items: any, currencyCode: any) => {
    let total = 0;
    let typeTitle = '';
    switch (type) {
      case DOC_TYPE.INVOICE:
        typeTitle = 'Linked Invoices';
        break;
      case DOC_TYPE.SALES_ORDER:
        typeTitle = 'Linked Sales Orders';
        break;
      case DOC_TYPE.WORK_ORDER:
        typeTitle = 'Linked Work Orders';
        break;
      case DOC_TYPE.ORDER:
        typeTitle = 'Linked Purchase Orders';
        break;
      default:
        break;
    }
    return (
      <div className="column" style={{ width: 200 }}>
        {typeTitle && <div className="row parent-width fw-m">{typeTitle}</div>}
        {type !== DOC_TYPE.ORDER && (
          <div className="row parent-width fw-m justify-content-between mr-s">
            <div className="column parent-width">
              {items?.map((item: any) => {
                const totalAmount =
                  type === DOC_TYPE.SALES_ORDER
                    ? +(item.dueAmount || 0)
                    : +(item.totalAmount || 0);
                total += +(totalAmount || 0);
                return (
                  <div className="row parent-width justify-content-between mt-xs">
                    <div
                      className="column cursor-hand"
                      onClick={(e) => {
                        e.stopPropagation();
                        return;
                      }}
                    >
                      {item.documentSequenceCode}
                    </div>
                    {totalAmount && (
                      <div className="column">
                        {Utility.getShortAmoutBlockForLabel(
                          currencyCode,
                          totalAmount.toString()
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {type !== (DOC_TYPE.WORK_ORDER && DOC_TYPE.ORDER) && (
          <div className="row justify-content-between parent-width mt-xs mb-s fw-m">
            <div className="column">Total Amount</div>
            <div className="column">
              {Utility.getShortAmoutBlockForLabel(currencyCode, total + '')}
            </div>
          </div>
        )}
        {type === DOC_TYPE.ORDER && (
          <div className="row justify-content-between parent-width mt-xs mb-s">
            <div className="row parent-width fw-m justify-content-between  mr-s">
              <div className="column parent-width gap-1">
                {items?.map((item: any) => {
                  return (
                    <div
                      className="row cursor-hand gap-1 align-items-end "
                      onClick={(e) => {
                        e.stopPropagation();
                        return;
                      }}
                    >
                      <DKLabel text={item.documentSequenceCode} />
                      <DKLabel
                        className="text-gray fs-s"
                        text={`(${Utility.convertInTitleCase(
                          item.documentType.replaceAll('_', ' ')
                        )})`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getLinkedWorkOrders = (rowData: any) => {
    const linkedWorkOrders = [];
    if (rowData.linkedWorkOrders?.length > 0) {
      linkedWorkOrders.push(...rowData.linkedWorkOrders);
    }
    if (rowData.linkedDocuments?.length > 0) {
      const linkedDrafts = rowData.linkedDocuments.filter(
        (item: any) => item.documentType === `DRAFT_${DOC_TYPE.WORK_ORDER}`
      );
      linkedWorkOrders.push(...linkedDrafts);
    }

    return linkedWorkOrders;
  };

  const getLinkedSalesOrders = (rowData: any) => {
    const linkedSalesOrders = [];
    if (rowData.linkedSalesOrders?.length > 0) {
      linkedSalesOrders.push(...rowData.linkedSalesOrders);
    }
    if (rowData.linkedDocuments?.length > 0) {
      const linkedDrafts = rowData.linkedDocuments.filter(
        (item: any) => item.documentType === `DRAFT_${DOC_TYPE.SALES_ORDER}`
      );
      linkedSalesOrders.push(...linkedDrafts);
    }

    return linkedSalesOrders;
  };

  const getLinkedSalesInvoices = (rowData: any) => {
    const linkedSalesInvoices = [];
    if (rowData.linkedSalesInvoices?.length > 0) {
      linkedSalesInvoices.push(...rowData.linkedSalesInvoices);
    }
    if (rowData.linkedDocuments?.length > 0) {
      const linkedDrafts = rowData.linkedDocuments.filter(
        (item: any) => item.documentType === `DRAFT_${DOC_TYPE.INVOICE}`
      );
      linkedSalesInvoices.push(...linkedDrafts);
    }

    return linkedSalesInvoices;
  };

  const getTootltipContent = (rowData: any) => {
    const linkedSalesInvoices = getLinkedSalesInvoices(rowData);
    const linkedSalesOrders = getLinkedSalesOrders(rowData);
    const linkedWorkOrders = getLinkedWorkOrders(rowData);
    const linkedPurchaseOrders = rowData?.linkedDocuments?.filter(
      (ele: any) => ele.documentType === DOC_TYPE.ORDER
    );
    return (
      <>
        {linkedSalesInvoices?.length > 0 &&
          getTooltipContainer(
            DOC_TYPE.INVOICE,
            linkedSalesInvoices,
            rowData.currencyCode
          )}
        {linkedSalesInvoices?.length > 0 && linkedSalesOrders?.length > 0 && (
          <br />
        )}
        {linkedWorkOrders?.length > 0 &&
          getTooltipContainer(
            DOC_TYPE.WORK_ORDER,
            linkedWorkOrders,
            rowData.currencyCode
          )}
        {linkedWorkOrders?.length > 0 && linkedWorkOrders?.length > 0 && <br />}
        {linkedSalesOrders?.length > 0 &&
          getTooltipContainer(
            DOC_TYPE.SALES_ORDER,
            linkedSalesOrders,
            rowData.currencyCode
          )}
        {linkedSalesOrders?.length > 0 && linkedSalesOrders?.length > 0 && (
          <br />
        )}
        {linkedPurchaseOrders?.length > 0 &&
          getTooltipContainer(
            DOC_TYPE.ORDER,
            linkedPurchaseOrders,
            rowData.currencyCode
          )}
      </>
    );
  };

  const getAmountBlock = (docCurrency: string, amount: number) => {
    const tenantCurrency = tenantInfo.currency;
    let exchangeRate = 1;
    if (showTotalInBaseCurrency && tenantCurrency !== docCurrency) {
      exchangeRate = activeMultiCurrencyList.find(
        (currency) => currency.currencyCode === docCurrency
      )?.currencyExchangeRate;
    }
    if (exchangeRate) {
      return Utility.getAmoutBlockForGrid(
        showTotalInBaseCurrency ? tenantCurrency : docCurrency,
        amount / exchangeRate + ''
      );
    }
  };

  const getRowData = (quotesList: any[]) => {
    let rowData: any[] = [];
    quotesList.forEach((quote, index) => {
      const contact = quote.contact?.name || quote.contactDto?.name || '';
      const approvalStatus =
        quote.approvalStatus && quote.approvalStatus !== null
          ? quote.approvalStatus
          : 'NOT_REQUIRED';
      let remarks = '';
      if (quote.multiApprovalDetails?.approvalHistory?.length > 0) {
        const allRemarks = quote.multiApprovalDetails.approvalHistory
          .filter((history: any) => history?.remarks)
          .map((history: any) => `Level ${history.level}: ${history.remarks}`);
        remarks = allRemarks.join(', ');
      }
      const truncatedRemarks =
        remarks.length > 50 ? `${remarks.substring(0, 50)}...` : remarks;
      const isSelected = currentSelectedRows.find((x) => x.id === quote.id);
      let row = {
        ...quote,
        [COLUMN_CODE.QUOTE.Number]:
          !quote.isDraftDocument &&
          typeof quote.documentSequenceCode !== 'undefined'
            ? quote.documentSequenceCode
            : Utility.getDraftIconBlockForGrid(quote.draftCode),
        [COLUMN_CODE.QUOTE.Code]: quote.quotationCode,
        [COLUMN_CODE.QUOTE.CustomerOrderNo]: quote.customerOrderNumber,
        [COLUMN_CODE.QUOTE.DocumentDate]: DateFormatService.getDateFromStr(
          quote.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        [COLUMN_CODE.QUOTE.DueDate]: DateFormatService.getDateFromStr(
          quote.validTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        [COLUMN_CODE.QUOTE.TotalAmount]: getAmountBlock(
          quote.currency,
          quote.totalAmount
        ),
        [COLUMN_CODE.QUOTE.Contact]: contact,
        [COLUMN_CODE.QUOTE.Status]: [quote.status],
        [COLUMN_CODE.QUOTE.Recurring]: [
          quote.recurring
            ? RECURRING_DOCUMENT_TYPE.RECURRING
            : RECURRING_DOCUMENT_TYPE.NON_RECURRING
        ],
        [COLUMN_CODE.QUOTE.FulfillmentStatus]: [quote.fulfillmentStatus],
        [COLUMN_CODE.QUOTE.Id]: quote.id ? quote.id : index,
        [COLUMN_CODE.QUOTE.Remarks]: truncatedRemarks,
        [COLUMN_CODE.QUOTE.ApprovalStatus]:
          APPROVAL_STATUS_LIST[approvalStatus] +
          getApprovalLevel(quote, APPROVAL_STATUS_LIST[approvalStatus]),
        recurringActivated: quote.recurringActivated,
        backOrder: quote.backOrder,
        currencyCode: quote.currency,
        linkedSalesInvoices: quote['linkedSalesInvoices'],
        linkedSalesOrders: quote['linkedSalesOrders'],
        linkedWorkOrders: quote?.linkedDocuments?.filter(
          (woItem: any) => woItem.documentType === DOC_TYPE.WORK_ORDER
        ),
        [COLUMN_CODE.QUOTE.CreatedBy]: [quote.createdBy],
        rowType:
          typeof quote.documentSequenceCode === 'undefined'
            ? 'draft'
            : DOCUMENT_TYPE.QUOTATION,
        allowRowEdit: true,
        rowContextMenu: getContextMenuForRow(quote),
        rowButtons: getButtonsForRow(quote),
        selected: isSelected ? true : false
      };
      if (quote.customField && quote.customField.length > 0) {
        quote.customField.forEach((cf: any) => {
          const columnCode: string = `${COLUMN_TYPE.CUSTOM}_${cf.code}_${cf.id}`;
          const colType = columnConfig.find(
            (config: any) => config.columnCode === columnCode
          )?.type;
          const cfInAvaialbleCFs = availableCustomFields?.content?.find(
            (field: any) => field.id === cf.id
          );
          const isUserTypeCF = cfInAvaialbleCFs
            ? cfInAvaialbleCFs.fieldType === CUSTOM_FIELD_TYPE.USER
            : false;
          let cfValue = '';
          if (isUserTypeCF) {
            const tempCF = cfInAvaialbleCFs?.attributes?.find(
              (attr: any) => attr.code === cf.value
            );
            cfValue = tempCF ? tempCF.value : '';
          } else {
            cfValue = cf ? cf.value : '';
          }
          row = {
            ...row,
            [columnCode]: colType === INPUT_TYPE.SELECT ? [cfValue] : cfValue
          };
        });
      }
      rowData.push(row);
    });
    return rowData;
  };

  const getButtonsForRow = (quote: any) => {
    let buttons: any[] = [];
    if (
      typeof quote.documentSequenceCode !== 'undefined' &&
      !quote.isDraftDocument
    ) {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.CONVERT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        ((isSalesOrderVisible() &&
          (quote.linkedSalesOrders === null ||
            quote.linkedSalesOrders?.length === 0)) ||
          !isSalesOrderVisible()) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.CONVERT
        ) &&
        quote.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP
      ) {
        buttons.push({
          title: 'Convert to Invoice',
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: ({ rowIndex, rowData }: any) => {
            loadQuoteForConversion(
              rowData,
              POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_OPTIONS
            );
          }
        });
      }
    } else {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EDIT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.EDIT
        )
      ) {
        buttons.push({
          title: 'Edit',
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => {
            onQuoteEdit(data);
          }
        });
      }
    }
    const linkedPO = quote?.linkedDocuments?.find(
      (l: any) => l.documentType === DOC_TYPE.ORDER
    );
    const docType = quote?.linkedDocuments?.[0]?.documentType;
    if (linkedPO) {
      buttons.push({
        title: 'Linked Order',
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: (data: any) => {
          if (docType === DOC_TYPE.ORDER) {
            loadLinkedOrder(linkedPO.documentCode);
          }
        }
      });
    }
    return buttons;
  };

  const getContextMenuForRow = (quote: any) => {
    let menuItems: any[] = [];

    if (quote.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']) {
      if (
        Utility.isApproverButtonShow(
          quote.documentType || DOCUMENT_TYPE.QUOTATION,
          quote,
          APPROVAL_STATUS.APPROVED
        )
      ) {
        menuItems.push({
          title: 'Approve',
          icon: DKIcons.ic_check_mark,
          className: 'p-v-0',
          onClick: (data: any) => {
            setShowApprovalOrRejectPopup(true);
            setApprovalOrRejectData({
              title: 'Approve',
              actionDetails: data
            });
          }
        });
      }
      if (
        Utility.isApproverButtonShow(
          quote.documentType || DOCUMENT_TYPE.QUOTATION,
          quote,
          APPROVAL_STATUS.REJECTED
        )
      ) {
        menuItems.push({
          title: 'Reject',
          icon: DKIcons.ic_close,
          className: 'p-v-0',
          onClick: (data: any) => {
            setShowApprovalOrRejectPopup(true);
            setApprovalOrRejectData({
              title: 'Reject',
              actionDetails: data
            });
          }
        });
      }
    }

    if (Utility.isEmpty(quote?.draftCode)) {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EDIT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.EDIT
        )
      ) {
        menuItems.push({
          title: 'Edit',
          icon: DKIcons.ic_edit,
          onClick: (data: any) => {
            onQuoteEdit(data);
          }
        });
      } else if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.VIEW,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.VIEW
        )
      ) {
        menuItems.push({
          title: 'Open',
          icon: DKIcons.ic_open,
          onClick: (data: any) => {
            onQuoteEdit(data);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.FULFILL,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
        ) &&
        !isSalesOrderVisible()
      ) {
        menuItems.push({
          title: localizedText('Fulfill'),
          icon: DKIcons.ic_delivery,
          onClick: (data: any) => fulfillment(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RETURN,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.CREATE
        ) &&
        !isSalesOrderVisible()
      ) {
        menuItems.push({
          title: 'Create Sales Return',
          icon: DKIcons.ic_delivery,
          onClick: (data: any) => salesReturn(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.CONVERT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        ((isSalesOrderVisible() &&
          (quote.linkedSalesOrders === null ||
            quote.linkedSalesOrders?.length === 0)) ||
          !isSalesOrderVisible()) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.CONVERT
        ) &&
        quote.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP
      ) {
        menuItems.push({
          title: 'Convert to Invoice',
          icon: DKIcons.ic_convert_3,
          onClick: ({ rowIndex, rowData }: any) => {
            loadQuoteForConversion(
              rowData,
              POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_OPTIONS
            );
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.CONVERT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        isSalesOrderVisible() &&
        (quote.linkedSalesInvoices === null ||
          quote.linkedSalesInvoices?.length === 0 ||
          quote.linkedSalesOrders?.length > 0) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.CONVERT
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE)
      ) {
        menuItems.push({
          title: 'Convert to Sales Order',
          icon: DKIcons.ic_convert_3,
          onClick: ({ rowIndex, rowData }: any) => {
            loadQuoteForConversion(
              rowData,
              POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_TO_SO_OPTIONS
            );
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.COPY,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.CREATE
        )
      ) {
        menuItems.push({
          title: 'Copy',
          icon: DKIcons.ic_copy,
          onClick: ({ rowIndex, rowData }: any) => {
            loadQuoteForCopy(rowData);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.VIEW,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.VIEW
        ) &&
        !Utility.isEmpty(quote?.contactDto?.contactNumber)
      ) {
        menuItems.push({
          title: 'Whatsapp',
          icon: ic_whatsapp,
          onClick: ({ rowIndex, rowData }: any) => {
            sendDocumentLinkThroughWhatsapp(quote);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.PRINT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.VIEW)
      ) {
        menuItems.push({
          title: 'Print',
          icon: DKIcons.ic_printer,
          onClick: ({ rowIndex, rowData }: any) => {
            if (!isTabletView()) {
              setQuoteForPrint(rowData);
            } else {
              Utility.postMobileAppActions(
                MOBILE_APP_ACTIONS.MOBILE_PRINT + ':' + JSON.stringify(rowData)
              );
            }
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EMAIL,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.EMAIL)
      ) {
        menuItems.push({
          title: 'Email',
          icon: DKIcons.ic_email,
          onClick: ({ rowIndex, rowData }: any) => {
            setQuoteForEmail(rowData);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.DESIGN,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.DESIGN)
      ) {
        menuItems.push({
          title: 'Design',
          icon: DKIcons.ic_paint,
          onClick: (data: any) => {
            openDesigner(data.rowData);
          }
        });
      }
      if (tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_QUOTATION) {
        menuItems.push({
          title: 'Rate Analysis',
          icon: DKIcons.ic_document,
          onClick: ({ rowIndex, rowData }: any) => {
            getRateAnalysis(rowData);
          }
        });
      }
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.ATTACHMENT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.EDIT)
      ) {
        menuItems.push({
          title: 'Attachment',
          icon: DKIcons.ic_attachment,
          onClick: (data: any) => onAttachmentCLicked(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.FULFILL_RECORD,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
        ) &&
        !isSalesOrderVisible()
      ) {
        menuItems.push({
          title: localizedText('Fulfillment Records'),
          icon: DKIcons.ic_document,
          onClick: (data: any) => getFulfillmentRecords(data)
        });
      }
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RETURN_RECORDS,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.VIEW
        ) &&
        !isSalesOrderVisible()
      ) {
        menuItems.push({
          title: 'Sales Returns Records',
          icon: DKIcons.ic_document,
          onClick: (data: any) => getSalesReturnRecords(data)
        });
      }
    } else {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EDIT,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.EDIT
        )
      ) {
        menuItems.push({
          title: 'Edit',
          icon: DKIcons.ic_edit,
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => {
            onQuoteEdit(data);
          }
        });
      }
      if (tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_QUOTATION) {
        menuItems.push({
          title: 'Rate Analysis',
          icon: DKIcons.ic_document,
          onClick: ({ rowIndex, rowData }: any) => {
            getRateAnalysis(rowData);
          }
        });
      }
    }

    if (quote.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']) {
      if (
        Utility.isApprovalColumn(DOC_TYPE.QUOTE) &&
        quote.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']
      ) {
        menuItems.push({
          title: 'Approval/Rejection History',
          icon: DKIcons.ic_document,
          className: 'p-v-0',
          onClick: (data: any) => {
            getApprovalHistory(data, true);
          }
        });
      }
    }

    if (
      Utility.isEmpty(quote?.draftCode) &&
      !Utility.isEmpty(quote.sequenceFormat)
    ) {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RECURRING,
          quote,
          DOC_TYPE.QUOTE
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.RECURRING)
      ) {
        menuItems.push({
          title: 'Set Recurring',
          icon: DKIcons.ic_reload,
          onClick: (data: any) => {
            recurringDocument(data);
          }
        });
      }
    }
    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.DELETE,
        quote,
        DOC_TYPE.QUOTE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.QUOTATION.DELETE
      )
    ) {
      menuItems.push({
        title: 'Delete',
        icon: DKIcons.ic_delete,
        onClick: async (data: any) => {
          const rowData = [data.rowData];
          if (!Utility.isEmpty(rowData)) {
            const isConfirmed = await confirm(
              t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`)
            );

            if (isConfirmed) {
              if (
                rowData[0].fulfillmentStatus[0] ===
                FULFILLMENT_STATUS.UNFULFILLED
              ) {
                onQuoteDelete(rowData);
              } else {
                showAlert(
                  '',
                  `${Utility.convertInTitleCase(localizedText('quote'))} <b>${
                    quote?.documentSequenceCode
                      ? quote?.documentSequenceCode
                      : ''
                  }</b> is in use, so cannot be deleted`
                );
              }
            }
          }
        }
      });
    }

    return menuItems;
  };

  // Load linked Order
  const loadLinkedOrder = async (code: any) => {
    try {
      const order = await PurchaseOrderService.fetchOrderDetails(code);
      const orderDetailsData: any = getUpdatedPurchaseOrderObject(order as any);
      if (!Utility.isEmpty(orderDetailsData)) {
        let payloadData: any = {
          id: orderDetailsData.id,
          type: LABELS.PURCHASE_ORDERS,
          title: LABELS.PURCHASE_ORDERS,
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: orderDetailsData,
          isSaved: true,
          draftsTableId,
          draftsColumnConfig
        };

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
      }
    } catch (err: any) {
      console.error('Error loading linked order: ', err);
    }
  };

  // Send document link through Whatsapp
  const sendDocumentLinkThroughWhatsapp = (rowData: any) => {
    const payload: any = {
      category: DOC_TYPE.QUOTE,
      contactCode: rowData?.contactCode,
      documentCode: rowData?.quotationCode,
      documentType: PrintService.getTemplateNameType(
        DOC_TYPE.QUOTE.toString().toLowerCase()
      ),
      event: TemplateMappingEvent.PRINT,
      parentDocumentType: null,
      parentDocumentCode: null
    };
    PrintService.getTemplatePrintInfo(payload).then(
      (data: any) => {
        const code = data?.code;
        if (Utility.isEmpty(code)) {
          showAlert('Error!', 'Error creating document link');
          return;
        }
        let link = `${process.env.REACT_APP_DOC_DESIGNER_URL}/?pid=${code}`;
        if (
          data.defaultTemplateMapping &&
          data.defaultTemplateMapping.templateId
        ) {
          link = `${link}&tid=${data.defaultTemplateMapping.templateId}`;
        }
        const sanitizedNumber = Utility.sanitizePhoneForWhatsapp(
          rowData.contactDto?.contactNumber
        );
        const messageToSend = WhatsAppHelper.getMessageForSalesDocument(
          getCapitalized(localizedText('quote')),
          rowData,
          link,
          tenantInfo?.name
        );
        const urlToOpen =
          ApiConstants.URL.WHATSAPP.WHATSAPP_URL(sanitizedNumber) +
          `?text=${encodeURIComponent(messageToSend)}`;
        window.open(urlToOpen, '_blank');
      },
      (err: any) => {
        showAlert('Error!', 'Error fetching document link');
      }
    );
  };

  /**
   * Load Invoice Filter Data
   */

  const getInitialFilterData = () => {
    if (Object.keys(quotesFilterData?.config).length) {
      if (!Utility.isEmpty(quotesFilterData.SearchTerm)) {
        // Set search value in datagrid
        setSearchTerm(quotesFilterData.SearchTerm);
      }

      if (quotesFilterData.queries.length) {
        setFilters(
          quotesFilterData?.queries.length
            ? deepClone(quotesFilterData?.queries)
            : []
        );
      }

      loadQuotes(quotesFilterData.config);
    } else {
      // loadQuotes({});
    }
  };

  const setFilterDataInStore = (
    config: any,
    SearchTerm?: any,
    queries?: any,
    filterDates?: any
  ) => {
    dispatch(
      quotesFilter({
        config,
        SearchTerm,
        queries,
        filterDates
      })
    );
  };

  const loadQuotes = async (config: QuotationAPIConfig) => {
    try {
      setUpdating(true);
      if (props.listByContact && !config?.Query?.includes('contactCode')) {
        config = Utility.isEmpty(config)
          ? deepClone(defaultConfig)
          : deepClone(config);
        config.Query += `${config.Query ? ',' : ''}contactCode=${
          props?.contact?.code
        }`;
      }
      QuotationService.apiConfig = config;
      await dispatch(fetchQuotes());
      setUpdating(false);
    } catch (err) {
      console.error('Error loading quotes: ', err);
      setUpdating(false);
    }
  };

  // Executes on search
  useEffect(() => {
    if (
      typeof debouncedSearchTerm !== 'undefined' &&
      debouncedSearchTerm !== null
    ) {
      const config: QuotationAPIConfig = {
        ...QuotationService.apiConfig,
        Page: 0,
        SearchTerm: searchTerm
      };
      setFilterDataInStore(
        { ...config },
        debouncedSearchTerm,
        deepClone(quotesFilterData?.queries)
      );
    }
  }, [debouncedSearchTerm]);

  const onSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onColumnUpdated = async (data: any) => {
    const { columnData } = data;
    const columnCode = columnData.columnCode as string;
    let columnIndex = columnConfig.findIndex(
      (column) => column.columnCode === columnCode
    );
    if (typeof columnData.id !== 'undefined') {
      const isCustomField = columnCode.startsWith('custom_');
      if (isCustomField && columnData.name !== columnConfig[columnIndex].name) {
        try {
          const customFieldId = columnCode.split('_')[2];
          if (customFieldId) {
            const customFieldResponse =
              await CustomFieldService.getCustomFieldById(+customFieldId);
            if (customFieldResponse) {
              let customField = customFieldResponse;
              customField = {
                ...customField,
                label: columnData.name
              };

              const updatedCustomField =
                await CustomFieldService.updateCustomField(
                  +customFieldId,
                  customField,
                  columnConfigTableId
                );
              if (updatedCustomField) {
                dispatch(
                  addQuoteColumnConfig({
                    tableId: columnConfigTableId as string,
                    config: updatedCustomField.newColumnConfig
                  })
                );
              }
            }
          } else {
            console.error('Error updating custom field');
          }
        } catch (err) {
          console.error('Error fetching custom field: ', err);
        }
      } else {
        updateColumnInfoAndStore(columnIndex, columnData);
      }
    }
  };

  const updateColumnInfoAndStore = (columnIndex: number, columnData: any) => {
    updateColumnInfo(columnConfigTableId as string, columnData);
    const currentColumnsConfig = [...columnConfig];
    currentColumnsConfig[columnIndex] = columnData;
    dispatch(updateColumnConfig(currentColumnsConfig));
  };

  const onColumnShifted = (data: IColumn[]) => {
    const updatedConfigArray = data.filter((x) => typeof x !== 'undefined');
    const columnIdArray = updatedConfigArray.map(
      (config) => config.id
    ) as string[];
    updateColumnShift(columnConfigTableId as string, columnIdArray);
    dispatch(updateColumnConfig(updatedConfigArray));
  };

  const onAddCustomFieldClicked = () => {
    const module = 'QUOTATION';
    showAddCustomField(
      {
        module: module,
        forDocument: true,
        columnConfig: columnConfig,
        tableId: columnConfigTableId
      },
      (newCustomField) => {
        loadQuotes({});
      }
    );
  };

  const dataHasReservedDocuments = (selectedDate: any[]) => {
    return selectedDate.some(
      (obj) => !obj.isDraftDocument && obj.reservedStock
    );
  };

  const handleBulkDelete = async (selectedData: any[]) => {
    setUpdating(true);
    const drafts = selectedData.filter((data: any) => data.isDraftDocument);
    const quotes = selectedData.filter((data: any) => !data.isDraftDocument);
    if (drafts.length) {
      const draftIds = drafts.map((draft) => draft.id);
      try {
        await dispatch(
          bulkDeleteDrafts({
            recordIds: draftIds,
            tableId: draftsTableId
          })
        );
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.QUOTES
          })
        );
      } catch (err) {
        console.error('Error deleting draft quotes: ', err);
      }
    }
    if (quotes.length) {
      const quoteIds = quotes.map((quote) => quote.id);
      try {
        const bulkDeleteResponse = await QuotationService.bulkDelete(quoteIds);
        if (bulkDeleteResponse.length) {
          if (bulkDeleteResponse.some((resp) => resp.httpStatus !== 'OK')) {
            const documentNumbers = bulkDeleteResponse
              .filter((resp) => resp.httpStatus !== 'OK')
              .map((resp) => `<li>${resp.documentSequenceCode}</li>`);
            showAlert(
              'Bulk Delete',
              `Following documents are already in use, So cannot be deleted:<ul>${documentNumbers.join(
                ''
              )}</ul>`
            );
          }
          loadQuotes({});
        }
      } catch (err) {
        showAlert(
          'Error!',
          `Error while bulk deleting ${Utility.convertInTitleCase(
            localizedText('quotes')
          )}`
        );
        loadQuotes({});
      }
    }
    setUpdating(false);
  };

  const onQuoteDelete = async (data: any) => {
    if (dataHasReservedDocuments(data)) {
      showAlert(
        'Warning',
        "You can't delete document(s) having reserved quantity"
      );
      return;
    }

    if (data?.length > 1) {
      handleBulkDelete(data);
      return;
    }
    const rowData = data[0];
    if (!Utility.isEmpty(rowData)) {
      setUpdating(true);

      if (rowData.isDraftDocument) {
        try {
          await dispatch(
            deleteDrafts({
              recordId: rowData.id,
              tableId: draftsTableId
            })
          );
          if (
            tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_QUOTATION
          ) {
            RateAnalysisService.delteDraftRateAnalysis(rowData.id);
          }
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.QUOTES
            })
          );
          setUpdating(false);
        } catch (err: any) {
          console.error('Error deleting draft or fetching list: ', err);
          setUpdating(false);
        }
      } else {
        const quoteId: number = rowData.id;
        const documentSequenceCode = rowData.documentSequenceCode;
        QuotationService.deleteQuote(quoteId)
          .then(
            (res) => {
              loadQuotes({});
            },
            (err: any) => {
              setUpdating(false);
              if (
                err?.data?.code === 406 &&
                err?.data?.httpStatus === 'NOT_ACCEPTABLE'
              ) {
                showAlert(
                  '',
                  `${Utility.convertInTitleCase(localizedText('quote'))} <b>${
                    documentSequenceCode ? documentSequenceCode : ''
                  }</b> is already in use, so cannot be deleted.`
                );
              } else {
                showAlert(
                  'Error',
                  `Error Deleting ${Utility.convertInTitleCase(
                    localizedText('quote')
                  )}`
                );
              }
            }
          )
          .catch((err) => {
            console.error(err, 'error');
          });
      }
    }
  };

  const onFilter = (updatedList: any[], queries: any[]) => {
    if (queries.length === 0) {
      const config: QuotationAPIConfig = {
        ...QuotationService.apiConfig,
        Query: `documentDate>=${filterDates.startDate},documentDate<=${filterDates.endDate}`,
        QueryParam: `&sort=documentDate&sortDir=DESC`,
        Page: 0
      };
      setFilterDataInStore(
        { ...config },
        quotesFilterData?.SearchTerm,
        deepClone(queries)
      );
      setFilters(queries);
    } else {
      const fileredQueries = removeNonCloumnFields(queries);
      let filteredQueryList = Utility.getFilterParameters(
        fileredQueries,
        REMOTE_CONFIG_TABLES.QUOTATION
      );
      const approvalStatusFilter = filteredQueryList
        .filter((item) => Utility.isEmpty(item?.approvalStatus?.condition))
        .find((item) => item?.approvalStatus);

      if (approvalStatusFilter) {
        const value = approvalStatusFilter?.approvalStatus;
        const { level, levelLabel } = getApprovalLevelAndLevelLabel(value);
        const payload: any = Utility.getKeyOfColumn(
          draftsColumnConfig,
          COLUMN_CODE.DRAFTS.Payload
        );
        const payloadKey = payload && payload.length ? payload[0].id : null;
        let draftQuoteData = [];
        if (payloadKey) {
          draftQuoteData = quotesDraftData?.map((data: any) => {
            if (data.cells[payloadKey]) {
              return {
                ...Utility.parseDraftDocumentPayload(data.cells[payloadKey]),
                id: data._id,
                isDraftDocument: true,
                createdBy: data?.createdBy,
                draftCode: data.cells.documentSequenceCode
              };
            }
          });
          if (draftQuoteData) {
            draftQuoteData = draftQuoteData?.filter((ele: any) => {
              if (!Utility.isEmpty(searchTerm)) {
                return (
                  ele.approvalStatus !== APPROVAL_STATUS.APPROVED &&
                  ele.draftCode?.search(new RegExp(searchTerm, 'i')) >= 0
                );
              } else {
                return ele.approvalStatus !== APPROVAL_STATUS.APPROVED;
              }
            });
          }
        }
        if (value.includes('PENDING')) {
          draftQuoteData =
            draftQuoteData?.filter(
              (ele) =>
                ele?.multiApprovalDetails?.currentLevel === Number(level) &&
                ele?.approvalStatus === APPROVAL_STATUS['PENDING_FOR_APPROVAL']
            ) ?? [];

          const rowData = getRowData(draftQuoteData ?? []);
          const { columnData, filterColumns } = quotes;
          const passingData = {
            columnData: columnData,
            rowData,
            filterColumns,
            filter: queries,
            originalData: rowData
          };
          setQuotes(passingData);
          setFilters(queries);
          return;
        } else if (value.includes('REJECTED')) {
          draftQuoteData =
            draftQuoteData?.filter(
              (ele) =>
                ele?.multiApprovalDetails?.currentLevel === Number(level) &&
                ele?.approvalStatus === APPROVAL_STATUS['REJECTED']
            ) ?? [];

          const rowData = getRowData(draftQuoteData ?? []);
          const { columnData, filterColumns } = quotes;
          const passingData = {
            columnData: columnData,
            rowData,
            filterColumns,
            filter: queries,
            originalData: rowData
          };
          setQuotes(passingData);
          setFilters(queries);
          return;
        }
      }

      const list = Utility.getFilteredQueryList(filteredQueryList);
      let withoutFilter, withFilter;
      if (
        list.newFilteredQueyListArr &&
        list.newFilteredQueyListArr.length > 0
      ) {
        withoutFilter = Utility.getQueryString(list.newFilteredQueyListArr);
      }
      if (list.customField && list.customField.length > 0) {
        assignProductModule('CLASS', 'CLASS', list?.customField);
        assignProductModule('CLASS', 'LOCATION', list?.customField);
        withFilter = Utility.getQueryString(list.customField);
      }
      withoutFilter = getQueryForNonColumnField(withoutFilter, queries);
      const config: QuotationAPIConfig = {
        ...QuotationService.apiConfig,
        Query:
          `documentDate>=${filterDates.startDate},documentDate<=${filterDates.endDate}` +
          (withoutFilter ? `,${withoutFilter}` : ''),
        QueryParam: `&sort=${QuotationService.apiConfig.Sort}&sortDir=${QuotationService.apiConfig.SortDir}&${withFilter}`,
        Page: 0,
        WithFilter: withFilter,
        WithoutFilter: withoutFilter
      };
      QuotationService.apiConfig = config;
      setFilterDataInStore(
        { ...config },
        quotesFilterData?.SearchTerm,
        deepClone(queries)
      );
      setFilters(queries);
    }
  };

  const assignProductModule = (key: string, label: string, list: any) => {
    if (tenantInfo.additionalSettings?.[key]?.assignClasses === 'ROW') {
      const cfInAvailableCFs = availableCustomFields?.content?.find(
        (field: any) => field.label === label
      );
      list?.forEach((field: any) => {
        field?.customfield?.forEach((data: any) => {
          if (data?.code === cfInAvailableCFs?.code) {
            if (!data?.module.includes('PRODUCT')) {
              data.module.push('PRODUCT');
            }
          }
        });
      });
    }
  };

  const onRowAdd = (newRow: any) => {
    if (newRow) {
      let updatedRowData = [...quotes.rowData];
      updatedRowData.unshift(newRow);
      setQuotes({
        ...quotes,
        rowData: updatedRowData,
        originalData: updatedRowData
      });
    }
  };

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const loadQuoteDetailsForEdit = (
    code: string,
    popupActionType: POPUP_CLICK_TYPE = POPUP_CLICK_TYPE.UPDATE_QUOTE
  ) => {
    showLoader('Loading...');
    QuotationService.getQuoteByCode(code).then(
      (data: any) => {
        removeLoader();
        const quoteDetailsData: any = getUpdatedQuoteObject(data);

        setEditableQuote(quoteDetailsData);
        const isDropShip = Utility.needDropshipPopup(quoteDetailsData);

        switch (popupActionType) {
          case POPUP_CLICK_TYPE.FULFILLMENT:
            if (isDropShip) {
              setShowDropshipConfirmationPopup(true);
            } else {
              setShowFulfillmentConfirmationPopup(true);
              setShowFulfillmentPopup(false);
            }
            break;
          case POPUP_CLICK_TYPE.COPY_QUOTE:
            const isINDTaxSystem =
              getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST;
            let quoteDetailsData: any = {
              ...data,
              id: null,
              documentSequenceCode: null,
              quotationCode: null,
              reservedStock: null,
              reservedQuantitiesData: null,
              quotationItemDtoList: data.quotationItemDtoList.map(
                (item: any) => {
                  return {
                    ...item,
                    id: null,
                    documentItemCode: null,
                    fulfilledQuantity: 0,
                    qtyConvertedToDropShip: 0,
                    qtyFulfilledFromToDropShip: 0,
                    //qtyConvertedToPps: 0,
                    reservedQuantitiesData: null,
                    igstAmount: isINDTaxSystem ? item.igstAmount : 0,
                    igstRate: isINDTaxSystem ? item.igstRate : 0,
                    cgstAmount: isINDTaxSystem ? item.cgstAmount : 0,
                    cgstRate: isINDTaxSystem ? item.cgstRate : 0,
                    sgstAmount: isINDTaxSystem ? item.sgstAmount : 0,
                    sgstRate: isINDTaxSystem ? item.sgstRate : 0,
                    cessAmount: isINDTaxSystem ? item.cessAmount : 0,
                    cessPercentage: isINDTaxSystem ? item.cessPercentage : 0,
                    nonEditableColumns:
                      item.type === PRODUCT_TYPE.NON_TRACKED &&
                      item.product?.stockUom === UOM_NA_ID
                        ? ['productQuantity']
                        : []
                  };
                }
              ),
              linkedDocuments: null,
              linkedSalesInvoices: null,
              linkedSalesOrders: null,
              fulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
              fulfillmentType: FULFILLMENT_TYPE.NONE,
              status: DOCUMENT_STATUS.OPEN,
              approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
              multiApprovalDetails: null,
              duplicate: true,
              entityId: data.id,
              recurring: false,
              recurringActivated: false,
              backOrder: false
            };
            if (!Utility.isEmpty(quoteDetailsData)) {
              let payloadData: any = {
                type: LABELS.QUOTES,
                title: Utility.convertInTitleCase(localizedText('quote')),
                isMaximized: true,
                isCenterAlign: true,
                populateFormData: quoteDetailsData,
                isSaved: true,
                tableId: draftsTableId,
                columnConfig: draftsColumnConfig
              };

              dispatch(
                createBlankDraft({ payloadData, draftType: DraftTypes.COPY })
              );
            }
            break;
          case POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_OPTIONS:
            setShowConversionPopup(true);
            break;
          case POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_TO_SO_OPTIONS:
            setShowSOConversionPopup(true);
            break;
          case POPUP_CLICK_TYPE.SET_RECURRING_DOCUMENT:
            setShowRecurringPopup(true);
            break;
          case POPUP_CLICK_TYPE.OPEN_ATTACHMENT:
            setShowAttachmentPopup(true);
            break;
          case POPUP_CLICK_TYPE.SALES_RETURN:
            setShowSalesReturnPopup(true);
            break;
          default:
            break;
        }
      },
      (err) => {
        removeLoader();
        console.error('Error while fetching quote details: ', err);
      }
    );
  };

  const loadLocalQuoteDetailsForEdit = (
    quotationDetails: any,
    popupActionType: POPUP_CLICK_TYPE = POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_OPTIONS
  ) => {
    const quoteDetailsData: any = getUpdatedQuoteObject(quotationDetails);
    const displayFulfillmentConfirmation =
      quoteDetailsData.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED &&
      quoteDetailsData.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP;
    setEditableQuote(quoteDetailsData);

    switch (popupActionType) {
      case POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_OPTIONS:
        setShowConversionPopup(true);
        break;
      case POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_TO_SO_OPTIONS:
        setShowSOConversionPopup(true);
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        if (displayFulfillmentConfirmation) {
          setShowFulfillmentConfirmationPopup(true);
          setShowFulfillmentPopup(false);
        } else {
          setShowFulfillmentConfirmationPopup(false);
          setShowFulfillmentPopup(true);
        }
        break;
      default:
        break;
    }
  };

  const loadQuotationFulfillmentRecords = (
    quoteDetails: any,
    popupActionType: POPUP_CLICK_TYPE
  ) => {
    const quoteCode: string = quoteDetails.quotationCode;
    const quoteDetailsData: any = quoteDetails;
    setEditableQuote(quoteDetailsData);

    let salesInvoiceCodeFilters = [
      `documentSequenceCode=${quoteDetailsData?.documentSequenceCode}`
    ];
    if (Utility.isNotEmpty(quoteDetails?.linkedSalesInvoices)) {
      salesInvoiceCodeFilters.push(
        ...(quoteDetails?.linkedSalesInvoices
          ?.filter((invoice: any) =>
            Utility.isNotEmpty(invoice?.documentSequenceCode)
          )
          ?.map(
            (invoice: any) =>
              `documentSequenceCode=${invoice?.documentSequenceCode}`
          ) ?? [])
      );
    }
    const salesInvoiceCodeQuery = salesInvoiceCodeFilters.join(',');
    setFulfillmentDetails([]);
    setShowFulfillmentRecordsPopup(false);
    FulfillmentService.getFulfillmentRecords(quoteCode, DOC_TYPE.QUOTE).then(
      (data1: any) => {
        if (
          Utility.isNotEmpty(salesInvoiceCodeQuery) &&
          (GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.PPS.READ
          ) ||
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.PPS.CREATE
            ))
        ) {
          FulfillmentService.getLinkedPPSFulfillmentRecords(
            salesInvoiceCodeQuery
          ).then((data2: any) => {
            setFulfillmentDetails((prev: any) => {
              if (Utility.isEmpty(data1) && Utility.isEmpty(data2?.content)) {
                showAlert('Error', 'Fulfillment record not found');
                return;
              }
              if (
                Utility.isNotEmpty(data1) &&
                Utility.isNotEmpty(data2?.content)
              ) {
                setFulfillmentType('Fulfillment Records');
              } else if (Utility.isNotEmpty(data1)) {
                setFulfillmentType('Fulfillment Records');
              } else {
                setFulfillmentType('PPS Records');
              }
              const mappedRecords = data2?.content?.map((row: any) => {
                return {
                  ...row,
                  category: 'PPS Fulfillment',
                  fulfillment_code: row?.documentSequenceCode
                };
              });
              setShowFulfillmentRecordsPopup(true);
              return [
                ...(prev ?? []),
                ...(data1 ?? []),
                ...(mappedRecords ?? [])
              ];
            });
          });
        } else {
          if (Utility.isEmpty(data1)) {
            showAlert('Error', 'Fulfillment record not found');
            return;
          }
          setFulfillmentType('Fulfillment Records');
          setFulfillmentDetails(data1);
          setShowFulfillmentRecordsPopup(true);
        }
      }
    );
  };

  // Check if minimized doc exists and maximize it
  const minimizedDocExists = (documentCode: string, isDraft: boolean) => {
    const minimizedDocInfo = getMinimizedDocInfo(
      documentCode,
      isDraft,
      DOC_TYPE.QUOTE
    );

    if (minimizedDocInfo.isMinimized) {
      let payloadData: any = {
        id: isDraft ? documentCode : minimizedDocInfo?.activeFormData?.id,
        isCenterAlign: true
      };
      dispatch(setDraftCenter(payloadData));
      dispatch(
        setPopulateFormData({
          id: documentCode,
          formdata: minimizedDocInfo.activeFormData
        })
      );
    }

    return minimizedDocInfo.isMinimized;
  };

  // Open document from document code in the URL
  const directOpenDocument = (documentCode: string) => {
    if (!Utility.isEmpty(actionInURL)) {
      // Check for action type
      if (actionInURL === DOC_URL_ACTION.CONVERT_TO_INVOICE) {
        // Check for action permission
        if (
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.QUOTATION.CONVERT
          )
        ) {
          showLoader();
          QuotationService.getQuoteByCode(documentCode).then(
            (data: any) => {
              let quoteDetailsData: any = getUpdatedQuoteObject(data);
              if (!Utility.isEmpty(quoteDetailsData)) {
                loadLocalQuoteDetailsForEdit(
                  quoteDetailsData,
                  POPUP_CLICK_TYPE.OPEN_CONVERT_QUOTE_OPTIONS
                );
              }
              removeLoader();
            },
            (err) => {
              showAlert('Error!', 'Error while fetching document details.');
              console.error('Error while fetching document details: ', err);
              removeLoader();
            }
          );
        } else {
          removeLoader();
          RouteManager.navigateToPage(PAGE_ROUTES.QUOTES);
          showAlert(
            'No Permissions!',
            `You don't have permission to convert ${localizedText(
              'quotes'
            )}, please contact owner/admin of this ${localizedText(
              'organisation'
            )}`
          );
        }
      } else {
        removeLoader();
        RouteManager.navigateToPage(PAGE_ROUTES.QUOTES);
      }
      return;
    }
    if (documentCode.toLowerCase() === 'create') {
      if (
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.CREATE
        )
      ) {
        if (!isOpeningForm) {
          addRecordClicked();
        }
      } else {
        showAlert(
          'No Permission!',
          `You don't have permission to create ${localizedText(
            'quote'
          )}, please contact owner/admin of this organisation`
        );
        return;
      }
      removeLoader();
    }
    if (documentCode.toLowerCase() !== 'create') {
      if (minimizedDocExists(documentCode, true)) {
        return;
      }
      const existingDraft = quotesDraftData?.find(
        (draft: any) => draft._id === documentCode
      );
      if (!Utility.isEmpty(existingDraft)) {
        removeLoader();
        const { type, payload, isMaximized, isCenterAlign, isSaved } =
          Utility.getKeysForDraftColumns(draftsColumnConfig);

        const formData = getUpdatedQuoteObject(
          Utility.parseDraftDocumentPayload(existingDraft.cells[payload[0].id])
        );
        let payloadData: any = {
          id: existingDraft._id,
          type: existingDraft.cells[type[0].id],
          title: existingDraft.cells[type[0].id],
          isMaximized: existingDraft.cells[isMaximized[0].id],
          isCenterAlign: existingDraft.cells[isCenterAlign[0].id],
          populateFormData: formData,
          isSaved: existingDraft.cells[isSaved[0].id],
          draftCode: existingDraft.cells.documentSequenceCode,
          draftsTableId,
          draftsColumnConfig
        };

        const isReadOnlyDraft =
          (isApprovalLockEnabled &&
            [
              APPROVAL_STATUS.PENDING,
              APPROVAL_STATUS.PENDING_FOR_APPROVAL
            ].includes(payloadData.populateFormData.approvalStatus)) ||
          Utility.isDraftReadOnly(payloadData.populateFormData);

        if (isReadOnlyDraft) {
          payloadData.title = 'Draft';
          payloadData.hideSave = true;
          payloadData.isReadOnlyDraft = true;
        }

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: isReadOnlyDraft ? DraftTypes.READONLY : DraftTypes.DRAFT
          })
        );
      } else {
        if (minimizedDocExists(documentCode, false)) {
          return;
        }
        showLoader(OPENING_DOCUMENT_LABEL);
        QuotationService.getQuoteByCode(documentCode).then(
          (data: any) => {
            let docDetailsData: any = getUpdatedQuoteObject(data);
            const isReadOnly = getIsReadOnly(docDetailsData);

            if (
              docDetailsData &&
              isQuoteEditBlockEnabledForMultiUser &&
              docDetailsData.editingLocked &&
              docDetailsData.editingBy !== AuthService.getUserID()
            ) {
              showAlert(
                `${localizedText('Quote')} is locked!`,
                `${getUserNameById(
                  docDetailsData.editingBy
                )} is editing this ${localizedText(
                  'quote'
                )} currently. You can view document in read-only mode.`
              );
            }

            if (!Utility.isEmpty(docDetailsData)) {
              let payloadData: any = {
                id: docDetailsData.id,
                type: LABELS.QUOTES,
                title: Utility.convertInTitleCase(localizedText('quote')),
                isMaximized: true,
                isCenterAlign: true,
                populateFormData: docDetailsData,
                isSaved: true,
                draftsTableId,
                draftsColumnConfig
              };

              dispatch(
                createBlankDraft({
                  payloadData,
                  draftType: isReadOnly
                    ? DraftTypes.READONLY
                    : DraftTypes.UPDATE
                })
              );
            }
            removeLoader();
          },
          (err) => {
            console.error('Error while fetching document details: ', err);
            removeLoader();
          }
        );
      }
    }
  };

  const getIsReadOnly = (docClicked: any) => {
    let isReadOnly = false;

    if (
      getLinkedSalesInvoices(docClicked)?.length ||
      getLinkedSalesOrders(docClicked)?.length ||
      docClicked.fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED ||
      GranularPermissionsHelper.hasDocumentReadOnly(
        PERMISSIONS_BY_MODULE.QUOTATION
      )
    ) {
      isReadOnly = true;
    }
    if (
      docClicked.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP ||
      docClicked.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP
    ) {
      isReadOnly = true;
    }
    if (docClicked.reservedStock) {
      isReadOnly = true;
    }
    let res = Utility.validationForClosedDate(docClicked);
    if (res.isDisable) {
      isReadOnly = res.isDisable;
    }

    if (
      isApprovalLockEnabled &&
      (docClicked.approvalStatus === APPROVAL_STATUS.PENDING_FOR_APPROVAL ||
        (docClicked.approvalStatus === APPROVAL_STATUS.APPROVED &&
          (docClicked.locked ?? true)))
    ) {
      isReadOnly = true;
    }

    if (
      isQuoteEditBlockEnabledForMultiUser &&
      docClicked.editingLocked &&
      docClicked.editingBy !== AuthService.getUserID()
    ) {
      isReadOnly = true;
    }

    return isReadOnly;
  };

  const onQuoteEdit = (data: any) => {
    const rowData = data.rowData;
    const quotesInStore: any[] = quotesData.content;
    const quoteClicked = quotesInStore.find(
      (x) => x.documentSequenceCode === data.rowData.documentSequenceCode
    );
    if (!!props?.listByContact) {
      if (quoteClicked?.quotationCode) {
        directOpenDocument(quoteClicked.quotationCode);
      }
    } else {
      if (rowData.isDraftDocument) {
        const draftId = rowData.id;
        RouteManager.navigateToPage(PAGE_ROUTES.QUOTES + `/${draftId}`);
      } else {
        RouteManager.navigateToPage(
          PAGE_ROUTES.QUOTES + `/${quoteClicked.quotationCode}`
        );
      }
    }
  };

  const columnDelete = (data: ColumnDeleteType) => {
    const [identifier, customFieldCode, customFieldId] =
      data.columnData.columnCode.split('_');
    if (
      !isNaN(Number(customFieldId)) &&
      data.columnData.id &&
      columnConfigTableId
    ) {
      CustomFieldService.deleteCustomField(
        Number(customFieldId),
        data,
        columnConfigTableId,
        columnConfig
      )
        .then((res) => {
          // const data: any = res;
          // const newColumnConfigs: IColumn[] = data.config;
          // dispatch(
          //   addQuoteColumnConfig({
          //     tableId: columnConfigTableId,
          //     config: newColumnConfigs
          //   })
          // );
        })
        .catch((err) => {
          dispatch(
            addQuoteColumnConfig({
              tableId: columnConfigTableId,
              config: columnConfig
            })
          );
          console.error(err, 'error');
        });
    }
  };

  const bulkFulfillment = (data: any) => {
    const hasFulfilled = Utility.isFulfilledReceived(data, MODULE_TYPE.SELL);

    if (hasFulfilled) {
      const docCodes = data
        .filter(
          (obj: any) =>
            obj.fulfillmentStatus[0] === FULFILLMENT_STATUS.FULLY_FULFILLED
        )
        .map((filteredObj: any) => '#' + filteredObj.documentSequenceCode);

      showAlert(
        'Warning',
        'The selected quote/invoice, ' +
          docCodes +
          ' are fully fulfilled. Please de-select this and try again'
      );

      return;
    }
    let selectedRowCode: any = selectedRows;
    if (selectedRowCode && selectedRowCode.length > 0) {
      FulfillmentService.getQuoteBulkFulfillmentDetails(selectedRows).then(
        (data: any) => {
          setBulkFulfillmentDetails(data);
          setEditableQuote(data[0]);
          setShowBulkFulfillmentPopup(true);
        }
      );
    }
  };

  const setBulkQuoteForPrint = (selectedRows: any[]) => {
    const selectedRowIds =
      selectedRows?.map((row: any) => row.quotationCode) || [];
    const quotesInStore: any[] = quotesData.content;
    const documentData = quotesInStore.filter((x) =>
      selectedRowIds.includes(x.quotationCode)
    );
    if (documentData && documentData.length > 1) {
      getBulkPrintPreview(
        TEMPLATE_CATEGORY.QUOTE,
        documentData.map((row: any) => row.quotationCode) || []
      );
    }
  };

  const onBulkPrintlick = (data: any) => {
    setBulkQuoteForPrint(data);
  };

  const setBulkFulfillmentRecords = (selectedRowIds: any[] = []) => {
    const quoteList: any[] = quotesData['content'];
    const quoteData =
      quoteList &&
      quoteList.filter(
        (quote) =>
          selectedRowIds && selectedRowIds.includes(quote.quotationCode)
      );
    if (quoteData && quoteData.length > 0 && selectedRowIds.length > 0) {
      const allEqual = quoteData.every((v) => {
        return (
          (v.contactCode || v.contactDto.code) === quoteData[0].contactCode &&
          v.status === DOCUMENT_STATUS.OPEN &&
          v.fulfillmentStatus !== FULFILLMENT_STATUS.FULLY_FULFILLED &&
          (v.fulfillmentType === FULFILLMENT_TYPE.DEFAULT ||
            v.fulfillmentType === FULFILLMENT_TYPE.NONE)
        );
      });
      if (allEqual) {
        setBulkFulfillmentRows(quoteData);
        return;
      } else {
        setBulkFulfillmentRows([]);
      }
    }
    setBulkFulfillmentRows([]);
  };

  const updateBulkPrintRecord = (selectedRows: any[] = []) => {
    if (selectedRows && selectedRows.length > 1) {
      let draft = selectedRows.find(
        (x) => x.isDraftDocument === true || x.rowType === 'draft'
      );
      if (!draft) {
        setBulkPrintRecordData(selectedRows);
        return;
      }
    }
    setBulkPrintRecordData([]);
  };

  const setBulkApprovalRecords = (selectedRowIds: any[] = []) => {
    const allEqual = isBulkApprovalRejectCondition(
      selectedRowIds,
      APPROVAL_STATUS.APPROVED
    );
    if (allEqual) {
      setBulkApprovalRows(selectedRowIds);
      return;
    }
    setBulkApprovalRows([]);
  };
  const setBulkRejectRecords = (selectedRowIds: any[] = []) => {
    const allEqual = isBulkApprovalRejectCondition(
      selectedRowIds,
      APPROVAL_STATUS.REJECTED
    );
    if (allEqual) {
      setBulkRejectRows(selectedRowIds);
      return;
    }
    setBulkRejectRows([]);
  };

  const isBulkApprovalRejectCondition = (data: any, status: any) => {
    if (data && data.length > 1) {
      const allEqual = data.every((v: any) => {
        let payload: any = Utility.getKeyOfColumn(
          draftsColumnConfig,
          COLUMN_CODE.DRAFTS.Payload
        );
        let isApprovalConditionApplied = true;
        if (v.isDraftDocument) {
          let draft = quotesDraftData.find((x) => x._id === v.id);
          isApprovalConditionApplied = Utility.isApproverButtonShow(
            DOC_TYPE.QUOTE,
            Utility.parseDraftDocumentPayload(draft.cells[payload[0].id]),
            status
          );
        }
        return (
          v.isDraftDocument &&
          v.approvalStatus !== APPROVAL_STATUS_LIST['NOT_REQUIRED'] &&
          isApprovalConditionApplied
        );
      });
      return allEqual;
    }
  };

  const rowUpdate = (columnData: RowUpdateType) => {
    const quotesList: any[] = quotesData['content'];
    const quoteId: number = columnData.rowData.id;
    const quoteData = quotesList.filter((quote) => quote.id === quoteId)[0];
    const [identifier, customFieldCode, customFieldId] =
      columnData.columnKey.split('_');
    const customFieldColumnConfig = columnConfig.find(
      (column) => column.columnCode === columnData.columnKey
    );
    if (quoteData && customFieldCode) {
      const updatedQuote = Utility.updateCustomField(
        quoteData,
        customFieldCode,
        columnData,
        customFieldColumnConfig
      );
      if (updatedQuote) {
        let payload: any = deepClone(updatedQuote);
        payload = { ...payload, contact: payload.contactDto };
        delete payload?.items;
        delete payload?.contactDto;

        QuotationService.updateQuote(payload, updatedQuote?.id as number)
          .then((res) => {
            const response: any = res;
            const customFields: any[] = response.customField;
            updatedQuote.customField = customFields;
            dispatch(updateQuoteDetails(updatedQuote));
          })
          .catch((err) => {
            console.error(err, 'error');
          });
      }
    }
  };

  const onAttachmentCLicked = (data: any) => {
    const quotesList: any[] = quotesData['content'];
    const quoteId: number = data.rowData.id;
    const quoteData = quotesList.filter((quote) => quote.id === quoteId)[0];
    if (quoteData) {
      loadQuoteDetailsForEdit(
        quoteData.quotationCode,
        POPUP_CLICK_TYPE.OPEN_ATTACHMENT
      );
    }
  };

  const getRateAnalysis = (rowData: any) => {
    if (!Utility.isEmpty(rowData)) {
      let detail = {
        documentCode:
          rowData.rowType == 'draft' || rowData.isDraftDocument
            ? rowData.draftCode
            : rowData.quotationCode,
        documentType: 'QUOTATION',
        draftId: rowData.id,
        isDraft:
          rowData.rowType == 'draft' || rowData.isDraftDocument ? true : false,
        draftCode: rowData.draftCode
      };
      setRateAnalysisData(detail);
      setShowRateAnalysisForm(true);
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowFulfillmentPopup(false);
        setShowConversionPopup(false);
        setShowSOConversionPopup(false);
        setShowFulfillmentRecordsPopup(false);
        setShowDropShipWarningPopup(false);
        setShowBulkFulfillmentPopup(false);
        setShowSalesReturnPopup(false);
        setShowSalesReturnRecordsPopup(false);
        setShowApprovalHistoryPopup(false);
        setshowExpenseButton(false);
        setShowApprovalOrRejectPopup(false);
        setShowDropshipConfirmationPopup(false);
        setShowCNAndDNInfo({ ...showCNAndDNInfo, showPopup: false });
        if (!Utility.isEmpty(actionInURL) && showConversionPopup) {
          history.replace(PAGE_ROUTES.QUOTES);
        }
        break;
      case POPUP_CLICK_TYPE.UPDATE_QUOTE:
        addQuoteRef.current?.storeCallbacksRef.updateQuote();
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        addQuoteRef.current?.storeCallbacksRef.fulfillment();
        break;
      case POPUP_CLICK_TYPE.BULK_FULFILLMENT:
        addQuoteRef.current?.storeCallbacksRef.bulkFulfillment();
        break;
      case POPUP_CLICK_TYPE.SAVE_RECURRING_DOCUMENT:
        addQuoteRef.current?.storeCallbacksRef.saveRecurringDocument();
        break;
      case POPUP_CLICK_TYPE.AUTO_SEND_RECURRING_DOCUMENT:
        addQuoteRef.current?.storeCallbacksRef.autoSendRecurringDocument();
        break;
      case POPUP_CLICK_TYPE.CONFIRM_DROPSHIP:
        let poDetails: any = Utility.createDropShip(editableQuote);
        dispatch(
          createBlankDraft({
            payloadData: {
              title: LABELS.PURCHASE_ORDERS,
              type: LABELS.PURCHASE_ORDERS,
              tableId: draftsTableId,
              columnConfig: draftsColumnConfig,
              populateFormData: poDetails,
              isCenterAlign: true,
              isMaximized: true
            },
            draftType: DraftTypes.NEW
          })
        );
        setShowDropShipWarningPopup(false);
        break;
      case POPUP_CLICK_TYPE.SALES_RETURN:
        addQuoteRef.current?.storeCallbacksRef.updateQuote();
        break;
      case POPUP_CLICK_TYPE.CREATE_EXPENSE_DEPOSIT:
        expenseRef.current?.storeCallbacksRef.createExpenseDeposit();
        break;
      case POPUP_CLICK_TYPE.CREATE_CREDIT:
        createCreditRef.current?.storeCallbacksRef.createCredit();
        break;
    }
  };

  const salesReturn = (data: any) => {
    const quoteList: any[] = quotesData['content'];
    const quoteId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert('', 'Sales Return not allowed on draft');
      return;
    }
    const quoteData = quoteList.filter((quote) => quote.id === quoteId)[0];
    if (quoteData) {
      Promise.all([
        FulfillmentService.getFulfillmentRecords(
          quoteData.quotationCode,
          DOC_TYPE.QUOTE
        ),
        SalesReturnService.isFullyReturned(
          quoteData.quotationCode,
          DOC_TYPE.QUOTE
        )
      ])
        .then(
          (data: any) => {
            let returnedData = [];
            if (!Utility.isEmpty(data?.[1])) {
              returnedData = data[1];
            }
            let filteredFulfillment = data?.[0];
            if (
              data &&
              data[1] &&
              Array.isArray(returnedData) &&
              returnedData.length > 0 &&
              !Utility.hasQtyToReturn(
                returnedData,
                RETURN_MODE.SALE,
                filteredFulfillment
              )
            ) {
              showAlert(
                '',
                `This ${localizedText('quote')} has been fully returned.`
              );
            } else {
              loadQuoteDetailsForEdit(
                quoteData.quotationCode,
                POPUP_CLICK_TYPE.SALES_RETURN
              );
            }
          },
          (err: any) => {
            console.error('Error getting fulfillment records: ', err);
          }
        )
        .catch((err: any) => {
          loadQuoteDetailsForEdit(
            quoteData.quotationCode,
            POPUP_CLICK_TYPE.SALES_RETURN
          );
        });
    }
  };
  const recheckDpl = (contact: any) => {
    const billingAddress: any[] = [];
    const countryCodeMap: any = {};
    COUNTRIES_WITH_CURRENCIES.forEach((country) => {
      countryCodeMap[country?.country?.toLowerCase()] = country?.countryCode;
    });
    if (contact?.billingAddress) {
      contact?.billingAddress?.forEach((address: any) => {
        if (address?.country) {
          billingAddress.push({
            ...address,
            countryCode: countryCodeMap?.[address?.country?.toLowerCase()]
          });
        }
      });
    }
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    );
    if (!dplContactCustomField || dplContactCustomField?.length == 0) return;
    const payload = {
      name: contact?.name ?? '',
      billingAddress: billingAddress,
      customField: dplContactCustomField
    };
    showLoader();
    ContactService.recheck(contact?.id, payload)
      .then((res) => {
        removeLoader();
        if (res) {
          showToast(
            'The contact you are using is under denied party list',
            TOAST_TYPE.SUCCESS
          );
        } else {
          showToast(
            'The contact you are using is not under denied party list',
            TOAST_TYPE.SUCCESS
          );
        }
        dispatch(fetchContacts(''));
      })
      .catch((err) => {
        removeLoader();
      });
  };

  const fulfillment = (data: any) => {
    if (Utility.isUSorg() && Utility.isDPLSettingEnabled()) {
      recheckDpl(data?.rowData?.contactDto);
    }
    const quoteList: any[] = quotesData['content'];
    const quoteId: number = data.rowData.id;
    const isDraft: boolean = data.rowData.isDraftDocument;
    const isFulfilled: boolean =
      data.rowData.fulfillmentStatus[0] === FULFILLMENT_STATUS.FULLY_FULFILLED;
    if (isDraft) {
      showAlert('', 'Fulfillment against Draft Quoation is not allowed');
      return;
    }
    if (isFulfilled) {
      showAlert('Warning', 'This document is fully fulfilled');
      return;
    }
    const quoteData = quoteList.filter((quote) => quote.id === quoteId)[0];
    if (quoteData) {
      // Redirect to PPS page, if fulfillmentType is PPS
      // if (quoteData?.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
      //   showLoader();
      //    RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP, 'picking-list');
      //   return;
      // }
      const displayFulfillmentConfirmation =
        quoteData.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED &&
        quoteData.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP;
      if (displayFulfillmentConfirmation) {
        loadQuoteDetailsForEdit(
          quoteData.quotationCode,
          POPUP_CLICK_TYPE.FULFILLMENT
        );
      } else {
        loadQuoteDetailsForEdit(
          quoteData.quotationCode,
          POPUP_CLICK_TYPE.FULFILLMENT
        );
      }
    }
  };

  const getFulfillmentRecords = (data: any) => {
    const quoteList: any[] = quotesData['content'];
    const quoteId: number = data.rowData.id;
    const isDraft: boolean = data.rowData.isDraftDocument;
    const isNotFulfilled: boolean =
      data.rowData.fulfillmentStatus[0] === FULFILLMENT_STATUS.UNFULFILLED;
    if (isDraft) {
      showAlert(
        'Warning',
        'Cannot get fulfillment records against Draft Quote'
      );
      return;
    }
    if (isNotFulfilled) {
      showAlert('', "There's no fulfillment records in this document");
      return;
    }
    const quoteData = quoteList.filter((quote) => quote.id === quoteId)[0];
    if (quoteData) {
      loadQuotationFulfillmentRecords(
        quoteData,
        POPUP_CLICK_TYPE.FULFILLMENT_RECORDS
      );
    }
  };

  const getSalesReturnRecords = (data: any) => {
    const quoteList: any[] = quotesData['content'];
    const quoteId: number = data.rowData.id;
    const quoteData = quoteList.filter((quote) => quote.id === quoteId)[0];
    setEditableQuote(quoteData);
    SalesReturnService.isFullyReturned(quoteData.quotationCode, DOC_TYPE.QUOTE)
      .then((res: any) => {
        // let data = Utility.filterReturnRecords(res, DOC_TYPE.QUOTE);
        let data = res;
        if (data?.length === 0) {
          showAlert('', 'Sales Returns Records not available.');
        } else {
          setSalesReturnsDetails(data);
          setShowSalesReturnRecordsPopup(true);
        }
      })
      .catch((err) => {
        removeLoader();
        showAlert('Error', 'Unable to get Sales Returns Records');
      });
  };

  const getApprovalHistory = (data: any, isDraft: any) => {
    setEditableQuote(data?.rowData);
    const cachedApprovalDetails = data?.rowData?.multiApprovalDetails;
    if (isDraft && Utility.isEmpty(cachedApprovalDetails?.approvalHistory)) {
      showAlert('Approval/Rejection History', 'No History Found');
      return;
    } else if (Utility.isNotEmpty(cachedApprovalDetails?.approvalHistory)) {
      setApprovalHistoryData(cachedApprovalDetails);
      setShowApprovalHistoryPopup(true);
    } else {
      AutomationService.getApprovalHistory(
        data?.rowData?.documentSequenceCode,
        DOC_TYPE.QUOTE,
        draftsTableId
      )
        .then((res: any) => {
          if (res) {
            setApprovalHistoryData(res?.cells?.multiApprovalDetails);
            setShowApprovalHistoryPopup(true);
          } else {
            showAlert('Approval/Rejection History', 'No History Found');
          }
        })
        .catch((err) => {
          removeLoader();
          showAlert('Approval/Rejection History', 'No History Found');
        });
    }
  };

  const recurringDocument = (data: any) => {
    const quoteList: any[] = quotesData['content'];
    const quoteId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert('', 'Unable to set recurring document for Draft Document');
      return;
    }
    const quoteData = quoteList.filter((quote) => quote.id === quoteId)[0];
    if (quoteData) {
      loadQuoteDetailsForEdit(
        quoteData.quotationCode,
        POPUP_CLICK_TYPE.SET_RECURRING_DOCUMENT
      );
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.UPDATE_QUOTE:
        addQuoteRef.current.storeCallbacksRef.updateQuote = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowBulkFulfillmentPopup(false);
        setShowFulfillmentRecordsPopup(false);
        setShowSalesReturnPopup(false);
        setshowExpenseButton(false);
        setShowSalesReturnRecordsPopup(false);
        setShowApprovalHistoryPopup(false);
        setShowDropshipConfirmationPopup(false);
        setShowCNAndDNInfo({ ...showCNAndDNInfo, showPopup: false });
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT:
        setShowFulfillmentPopup(true);
        setisFulfillmentForDropship(false);
        setShowFulfillmentConfirmationPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT_FOR_DROPSHIP:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        setisFulfillmentForDropship(true);
        break;
      case POPUP_CALLBACKS_TYPE.DROPSHIP_FULFILLMENT:
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowDropShipWarningPopup(true);
        break;
      case POPUP_CALLBACKS_TYPE.PICK_PACK_SHIP:
        setShowFulfillmentConfirmationPopup(false);
        // RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP);
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addQuoteRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.BULK_FULFILLMENT:
        addQuoteRef.current.storeCallbacksRef.bulkFulfillment =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_RECURRING_DOCUMENT:
        addQuoteRef.current.storeCallbacksRef.saveRecurringDocument =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.AUTO_SEND_RECURRING_DOCUMENT:
        addQuoteRef.current.storeCallbacksRef.autoSendRecurringDocument =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.SALES_RETURN:
        addQuoteRef.current.storeCallbacksRef.updateQuote = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_EXPENSE_DEPOSITE:
        expenseRef.current.storeCallbacksRef.createExpenseDeposit =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CREDIT:
        createCreditRef.current.storeCallbacksRef.createCredit =
          passingData.data;
        break;
    }
  };

  const createDocForReturn = (data: any) => {
    let msg =
      'Returned Goods have been added back to your selected warehouse. To refund , create a Credit Note or a Direct Expense for this contact.';
    showAlert('Sales Return Created Successfully', msg, [
      {
        title: 'Do it later',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {}
      },
      {
        title: 'Direct Expense',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {
          const record: any = {
            gstExchangeRate: 1,
            exchangeRate: 1,
            referenceNumber: '',
            makePaymentItemDtoList: [],
            isTdsPaymentIndia: true,
            contactCode: editableQuote.contactCode,
            contactName: editableQuote.contact.name
          };
          setExpenseData(record);
          setshowExpenseButton(true);
        }
      },
      {
        title: 'Credit Note',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {
          createCNPayload(data);
        }
      }
    ]);
  };

  const createCNPayload = (data: any) => {
    const documentItems = editableQuote.items;
    const returnedItems = data.salesReturnItems;
    data['returnedItems'] = data.salesReturnItems;
    let arr: any = [];
    let srAccountDetails = documentItems
      .filter((item1: any) =>
        returnedItems.some(
          (item2: any) =>
            item1.productCode === item2.productCode &&
            item2.quantityToReturn > 0
        )
      )
      .reduce((res: any, item: any) => {
        let accountCode = item?.product?.salesReturnAccountCode;
        let obj: any = {};
        if (!res[accountCode]) {
          obj = {
            accountCode: accountCode,
            amountDue: 0,
            amount: 0,
            taxAmount: 0,
            taxCode: item.taxCode,
            taxList: []
          };
        }
        let qtyToReturn = returnedItems.find(
          (i: any) => i.productCode === item.productCode
        ).quantityToReturn;
        obj.amount += item.unitPrice * qtyToReturn;
        obj.taxAmount =
          (Number(item.taxAmount) / Number(item.productQuantity)) * qtyToReturn;
        arr.push(obj);
        return arr;
      }, {});

    const record: any = {
      gstExchangeRate: 1,
      exchangeRate: 1,
      referenceNumber: '',
      lineItems: arr,
      contactCode: editableQuote.contactCode,
      contact: {
        ...editableQuote.contact,
        code: editableQuote.contactCode
      },
      cnDate: data.salesReturnDate,
      currency: editableQuote.currency,
      type: 'SALES'
    };
    setShowCNAndDNInfo({
      showPopup: true,
      data: record,
      documentType: DOC_TYPE.CREDIT_NOTE
    });
  };

  const loadQuoteForConversion = (
    quoteDetails: any,
    clickType: POPUP_CLICK_TYPE
  ) => {
    const quotesInStore: any[] = quotesData.content;
    const quoteClicked = quotesInStore.find(
      (x) => x.documentSequenceCode === quoteDetails.documentSequenceCode
    );
    if (quoteClicked.quotationCode) {
      loadQuoteDetailsForEdit(quoteClicked.quotationCode, clickType);
    }
  };

  const loadQuoteForCopy = (quoteDetails: any) => {
    const quotesInStore: any[] = quotesData.content;
    const quoteClicked = quotesInStore.find(
      (x) => x.documentSequenceCode === quoteDetails.documentSequenceCode
    );
    if (quoteClicked.quotationCode) {
      loadQuoteDetailsForEdit(
        quoteClicked.quotationCode,
        POPUP_CLICK_TYPE.COPY_QUOTE
      );
    }
  };

  const openDesigner = (rowData: any) => {
    const docsInStore: any[] = quotesData.content;
    const docId = rowData.id;
    const data = docsInStore.filter((doc) => doc.id === docId)[0];
    if (data) {
      let docCode = data.quotationCode;
      let docType = DOC_TYPE.QUOTE;
      showLoader();
      PrintService.getDocumentPrintUUID(docCode, docType)
        .then(
          (response: any) => {
            removeLoader();
            const printExternalAppUrl = [
              ApiConstants.PRODUCT_URL_DOCUMENT_BUILDER,
              response.code
            ].join('/');
            window.open(printExternalAppUrl, '_blank');
          },
          (err: any) => {
            removeLoader();
            console.error('Failed to load the designer code', err);
          }
        )
        .catch((err: any) => {
          removeLoader();
          console.error('Failed to load the designer code', err);
        });
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(footerAction)) {
      let data: any;
      switch (footerAction.action) {
        case ActionMenuEvent.FULFILL:
          data = {
            rowData: footerAction.document
          };
          fulfillment(data);
          break;
        case ActionMenuEvent.FULLFILLMENT_RECORDS:
          data = {
            rowData: footerAction.document
          };
          getFulfillmentRecords(data);
          break;
        case ActionMenuEvent.SET_RECURRING:
          data = {
            rowData: footerAction.document
          };
          recurringDocument(data);
          break;
        default:
          break;
      }
      dispatch(setFooterAction(null));
    }
  }, [footerAction]);

  const setQuoteForPrint = (rowData: any) => {
    const quotesInStore: any[] = quotesData.content;
    const quoteClicked = quotesInStore.find(
      (x) => x.documentSequenceCode === rowData.documentSequenceCode
    );
    if (quoteClicked.quotationCode) {
      getPrintPreview(TEMPLATE_CATEGORY.QUOTE, {
        ...quoteClicked,
        contactCode: quoteClicked.contactCode,
        documentCode: quoteClicked.quotationCode,
        documentType: DOC_TYPE.QUOTE
      });
    }
  };

  const onEmailCallBack = (data: any, id: any) => {
    let emailIds = deepClone(emailTriggerIds);
    let quoteIds = emailIds[DOC_TYPE.QUOTE] ?? [];
    quoteIds.push(id);
    emailIds[DOC_TYPE.QUOTE] = quoteIds;
    dispatch(setEmailTriggerIds(emailIds));
  };

  const setQuoteForEmail = (rowData: any) => {
    const quotesInStore: any[] = quotesData.content;
    const quoteClicked = quotesInStore.find(
      (x) => x.documentSequenceCode === rowData.documentSequenceCode
    );
    if (quoteClicked.quotationCode) {
      getEmailPopup(
        TEMPLATE_CATEGORY.QUOTE,
        {
          ...quoteClicked,
          contactCode: quoteClicked.contactCode,
          documentCode: quoteClicked.quotationCode,
          documentType: DOC_TYPE.QUOTE
        },
        quoteEmailTemplateConfig,
        (data: any) => onEmailCallBack(data, quoteClicked.quotationCode)
      );
    }
  };

  const onApproveDraft = async (data: any, status: any, remarks: string) => {
    if (!isApprovalInprogress.current) {
      const rowData = data.rowData;
      if (rowData.isDraftDocument) {
        const draftIds = [rowData.id];
        isApprovalInprogress.current = true;
        setUpdating(true);
        try {
          await DraftService.updateApprovalStatus(
            draftsTableId,
            draftIds,
            DOC_TYPE.QUOTE,
            status,
            remarks
          );
        } catch (e) {
        } finally {
          isApprovalInprogress.current = false;
          setUpdating(false);
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.QUOTES
            })
          );
          loadQuotes({});
        }
      } else {
        /* For Created Quotes in APPROVAL FLOW triggered through CRM */
        try {
          isApprovalInprogress.current = true;
          const quote =
            quotesData?.content?.find(
              (quote: any) => rowData.id === quote.id
            ) || rowData;
          let { multiApproveRequired, multiApprovalHistory } =
            checkMultiApprovalRequired(
              {
                populateFormData: {
                  ...quote,
                  multiApprovalDetails: quote.multiApprovalDetails
                    ? deepClone(quote.multiApprovalDetails)
                    : null,
                  documentType: DOC_TYPE.QUOTE
                }
              },
              status
            );

          let approvalStatus =
            multiApproveRequired && status !== APPROVAL_STATUS.REJECTED
              ? APPROVAL_STATUS.PENDING_FOR_APPROVAL
              : status;

          const payload = {
            approvalStatus: approvalStatus,
            multiApprovalDetails: multiApprovalHistory,
            locked: true
          };
          await QuotationService.patchUpdateQuote(rowData.id, payload);
          loadQuotes({});
          multiApproveRequired &&
            sendTriggerOnApproval(
              { ...rowData, ...payload, documentType: DOC_TYPE.QUOTE },
              rowData.documentSequenceCode
            );
        } catch (err) {
          console.error('Error updating approval status: ', err);
        } finally {
          isApprovalInprogress.current = false;
        }
      }
    }
  };

  const sendTriggerOnApproval = (payload: any, draftCode: string) => {
    let emails = Utility.getApproverEmail(payload);
    let payloadObj = {
      contactCode: payload.contactCode,
      contactName:
        (typeof payload.contact === 'string'
          ? payload.contact
          : payload.contact?.name) || '',
      crm3DealId: payload.crm3DealId || '',
      documentSeqCode: draftCode || '',
      totalAmount: NumberFormatService.getNumber(
        payload.totalAmountInBaseCurrency || 0
      ),
      userName: AuthService.getUserName(),
      currency: payload.currency,
      approverMap: Object.fromEntries(emails),
      currentLevel: payload.multiApprovalDetails?.currentLevel || 1,
      approvalHistory: [], // not sending approval history for sending emails in case of rejected quotes as well..
      documentLink: `${
        Utility.isMRPWithURLCheck()
          ? ApiConstants.URL.APP_MRP_URL
          : ApiConstants.URL.APP_ERP_URL
      }${PAGE_ROUTES.QUOTES.replace('/', '')}/${payload.quotationCode || ''}`
    };
    QuotationService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  const bulkApprovalClick = async (data: any, remarks?: string) => {
    if (bulkApprovalRows?.length) {
      setUpdating(true);

      const draftIds = bulkApprovalRows
        .filter((data) => data.isDraftDocument)
        ?.map((data) => data.id);

      if (draftIds?.length) {
        try {
          await DraftService.updateApprovalStatus(
            draftsTableId,
            draftIds,
            DOC_TYPE.QUOTE,
            APPROVAL_STATUS.APPROVED,
            remarks
          );
        } catch (e) {
        } finally {
          setBulkRejectRows([]);
          setBulkApprovalRows([]);
          loadQuotes({});
          dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.QUOTES
            })
          );
          setUpdating(false);
        }
      }
    }
  };

  const bulkRejectClick = async (data: any, remarks: string) => {
    if (bulkRejectRows?.length) {
      setUpdating(true);

      const draftIds = bulkRejectRows
        .filter((data) => data.isDraftDocument)
        ?.map((data) => data.id);

      if (draftIds?.length) {
        try {
          await DraftService.updateApprovalStatus(
            draftsTableId,
            draftIds,
            DOC_TYPE.QUOTE,
            APPROVAL_STATUS.REJECTED,
            remarks
          );
        } catch (e) {
        } finally {
          setBulkRejectRows([]);
          setBulkApprovalRows([]);
          loadQuotes({});
          dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.QUOTES
            })
          );
          setUpdating(false);
        }
      }
    }
  };

  const getDateRangeString = () => {
    if (filterDates) {
      return (
        DateFormatService.getFormattedDateString(
          filterDates.startDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ) +
        ' to ' +
        DateFormatService.getFormattedDateString(
          filterDates.endDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      );
    } else {
      return '';
    }
  };

  const getDateRangePicker = () => {
    return (
      <div
        className={`position-absolute bg-white z-10 top-12 ${
          isDesktop ? 'right-20' : ''
        }`}
      >
        <ResponsiveDateRangePicker
          className="border shadow "
          isDateRange={true}
          selectedDate={new Date()}
          onClose={() => {
            setShowHideCalendar(false);
          }}
          showPresetList={true}
          color={'rgb(56, 79, 190)'}
          startDate={DateFormatService.getDateFromStr(
            filterDates.startDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          selectedStartDate={DateFormatService.getDateFromStr(
            filterDates.startDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          selectedEndDate={DateFormatService.getDateFromStr(
            filterDates.endDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setFilterDates({
                startDate: DateFormatService.getDateStrFromDate(
                  startDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                endDate: DateFormatService.getDateStrFromDate(
                  endDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              });

              SessionStorageService.saveConfigToStorage(
                MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
                DOC_TYPE.QUOTE,
                {
                  [FILTER_DATE]: {
                    start: startDate,
                    end: endDate
                  }
                }
              );
              setShowHideCalendar(false);
            }
          }}
        />
      </div>
    );
  };

  const addRecordClicked = async () => {
    if (props.listByContact && props.contact?.status === STATUS_TYPE.INACTIVE) {
      showAlert('Operation Not allowed', 'Selected Contact is Inactive');
      setIsOpeningForm(false);
      return;
    }

    setIsOpeningForm(true);
    if (contactIdInURL || props.listByContact) {
      const contactId = contactIdInURL || docCodeInURL;
      ContactService.getContactDetailsById(contactId).then(
        (contactInfo: any) => {
          let docDetailsData = QuoteInitialState;
          docDetailsData = {
            ...docDetailsData,
            contact: contactInfo,
            contactCode: contactInfo.code,
            contactDto: contactInfo,
            needToUpdateAddresses: true
          };
          if (!Utility.isEmpty(docDetailsData)) {
            let payloadData: any = {
              type: LABELS.QUOTES,
              title: Utility.convertInTitleCase(localizedText('quote')),
              isMaximized: true,
              isCenterAlign: true,
              populateFormData: docDetailsData,
              isSaved: true,
              tableId: draftsTableId,
              columnConfig: draftsColumnConfig
            };

            dispatch(
              createBlankDraft({ payloadData, draftType: DraftTypes.NEW })
            );
          }
          setIsOpeningForm(false);
        },
        (err: any) => {
          setIsOpeningForm(false);
          console.error('Error loading contact details: ', err);
        }
      );
    } else {
      await dispatch(
        createBlankDraft({
          payloadData: {
            title: Utility.convertInTitleCase(localizedText('quote')),
            type: LABELS.QUOTES,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig,
            isCenterAlign: true
          },
          draftType: DraftTypes.NEW
        })
      );
      setIsOpeningForm(false);
    }
  };

  const getHeaderAction = () => {
    let buttons: any[] = [];
    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.NEW,
        editableQuote,
        DOC_TYPE.QUOTE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.QUOTATION.CREATE
      )
    ) {
      buttons.push({
        title: isDesktop
          ? `+ New ${Utility.convertInTitleCase(localizedText('quote'))}`
          : 'Add',
        className: 'bg-button text-white ml-r',
        disabled: true,
        onClick: async () => {
          if (!isOpeningForm) {
            addRecordClicked();
          }
        }
      });
    }

    /* ********** QUOTES COLUMNS DISPLAY TOGGLE ********** */
    buttons.push(COLUMN_VISIBILITY_BUTTON_CONFIG);

    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.EXPORT,
        editableQuote,
        DOC_TYPE.QUOTE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.QUOTATION.EXPORT
      ) &&
      !isMobileWebView()
    ) {
      buttons.push({
        className: 'border-m bg-white ml-r position-relative',
        icon: DKIcons.ic_export,
        onClick: () => {
          setShowExportOptions(true);
        }
      });
    }
    if (
      Utility.isSupportedCountriesForImport(tenantInfo.country) &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.IMPORT,
        editableQuote,
        DOC_TYPE.QUOTE
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.QUOTATION.IMPORT
      ) &&
      !isMobileWebView() &&
      !props.listByContact
    ) {
      buttons.push({
        icon: DKIcons.ic_import,
        className: 'border-m bg-white ml-r',
        onClick: () => {
          importButtonClick();
        }
      });
    }

    buttons.push({
      icon: DKIcons.ic_settings,
      className: 'border-m bg-white ml-r settings-btn display-only-web',
      onClick: () => {
        setShowSettingsPopup((prevValue) => !prevValue);
      }
    });

    buttons.push({
      title: isDesktop && !isTabletView() ? getDateRangeString() : '',
      className: 'border-m bg-white ml-r position-relative',
      icon: DKIcons.ic_calendar,
      onClick: () => {
        setShowHideCalendar(true);
      }
    });
    return buttons;
  };

  const sortQuotes = (data: any) => {
    let oldConfig: any = QuotationService.apiConfig;
    let config: QuotationAPIConfig = {
      ...QuotationService.apiConfig,
      Query:
        `documentDate>=${filterDates.startDate},documentDate<=${filterDates.endDate}` +
        (oldConfig.WithoutFilter ? `,${oldConfig.WithoutFilter}` : ''),
      QueryParam: `&sort=${data.columnData.key}&sortDir=${data.order}&${
        oldConfig.WithFilter ?? ''
      }`,
      Sort: data.columnData.key,
      SortDir: data.order
    };

    if (data.columnData.key === 'totalAmount') {
      config = {
        ...QuotationService.apiConfig,
        Query:
          `documentDate>=${filterDates.startDate},documentDate<=${filterDates.endDate}` +
          (oldConfig.WithoutFilter ? `,${oldConfig.WithoutFilter}` : ''),
        QueryParam: `&sort=totalAmountInBaseCurrency&sortDir=${data.order}&${
          oldConfig.WithFilter ?? ''
        }`,
        Sort: 'totalAmountInBaseCurrency',
        SortDir: data.order
      };
    }
    QuotationService.apiConfig = config;
    loadQuotes(config);
  };

  const showExportOptionsList = () => {
    return (
      <DKListPicker
        data={EXPORT_FILE_TYPE}
        className={`mr-l shadow-m absolute z-10 ${isDesktop ? '' : 'left-20'}`}
        style={{ width: 81 }}
        onSelect={(index: any, value: any) => {
          if (index === 0) {
            exportQuotes(EXPORTED_FILES_TYPE_EXTENSION.PDF);
          } else if (index === 1) {
            exportQuotes(EXPORTED_FILES_TYPE_EXTENSION.XLS);
          } else {
            exportQuotes(EXPORTED_FILES_TYPE_EXTENSION.CSV);
          }
        }}
        onClose={() => {
          setShowExportOptions(false);
        }}
      />
    );
  };

  const exportQuotes = (fileFormat: EXPORTED_FILES_TYPE_EXTENSION) => {
    EximService.exportFiles(
      fileFormat,
      MODULES_NAME.QUOTES,
      QuotationService.apiConfig
    )
      .then((res) => {
        removeLoader();
        setShowExportOptions(false);
      })
      .catch((err) => {
        removeLoader();
        console.error('Error exporting quote: ', err);
        showAlert('Error', 'Unable to export quote');
      });
  };

  const importButtonClick = () => {
    RouteManager.navigateToPage(IMPORT_CONSTANTS.ROUTE.QUOTATION);
  };

  const showMainDocumentGridSettings = () => {
    return (
      <MainDocumentGridSettings
        showTotalInBaseCurrency={showTotalInBaseCurrency}
        onClose={() => setShowSettingsPopup(false)}
        onShowTotalInBaseCurrencyChange={(value: boolean) => {
          setShowTotalInBaseCurrency((prevValue) => {
            SessionStorageService.saveConfigToStorage(
              MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
              DOC_TYPE.QUOTE,
              {
                [SHOW_TOTAL_IN_BASE_CURRENCY]: value
              }
            );
            return value;
          });
        }}
      />
    );
  };

  return (
    <div
      className="column parent-width flex-1 main-holder-padding padding-bottom-50"
      style={
        props.listByContact
          ? {
              paddingLeft: 4
            }
          : {}
      }
    >
      <div className="column parent-width mb-s position-relative flex-1">
        <div className="parent-width row position-relative">
          <div className="position-absolute" style={{ right: 170, top: 45 }}>
            {showExportOptions && showExportOptionsList()}
          </div>
        </div>
        <div
          className="position-absolute z-index-3"
          style={{ right: 35, top: 45 }}
        >
          {showSettingsPopup && showMainDocumentGridSettings()}
        </div>
        {showHideCalendar && getDateRangePicker()}
        <DataGridHolder
          tableName={TABLES.QUOTATION}
          displayTableName={Utility.convertInTitleCase(localizedText('quotes'))}
          filterTableName={REMOTE_CONFIG_TABLES.QUOTATION}
          gridIcon="📋"
          needBoldTheme={isDesktop}
          extraWidth={props.listByContact ? CONTACT_LEFT_PANEL_WIDTH : 0}
          needBorder={true}
          needShadow={false}
          allowRowEdit={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.QUOTATION.CREATE
          )}
          allowColumnEdit={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.QUOTATION.CREATE
          )}
          allowRowAdd={true}
          allowColumnAdd={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.QUOTATION.CREATE
          )}
          allowColumnDelete={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.CUSTOM_FIELDS.DELETE
          )}
          allowColumnShift={true}
          allowColumnVisibilityToggle={true}
          allowSearch={!props.listByContact}
          allowFilter={true}
          onRowDelete={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.QUOTATION.DELETE
            )
              ? onQuoteDelete
              : undefined
          }
          onRowEdit={onQuoteEdit}
          updating={updating || loadingStatus === API_STATUS.LOADING}
          actionColumnWidth={200}
          headerButtons={getHeaderAction()}
          allowBulkOperation={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.QUOTATION.CREATE
          )}
          allowDataExport={true}
          gridData={quotes}
          onSearch={onSearch}
          onColumnAddClick={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CUSTOM_FIELDS.CREATE
            )
              ? onAddCustomFieldClicked
              : () => {}
          }
          onColumnUpdate={onColumnUpdated}
          onColumnShift={onColumnShifted}
          onFilter={onFilter}
          filter={Utility.checkFilterTypeDate(quotes.filter)}
          onRowAdd={onRowAdd}
          onFulfillment={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
            )
              ? fulfillment
              : undefined
          }
          onBulkFulfillment={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
            )
              ? bulkFulfillment
              : undefined
          }
          bulkFulfillmentRows={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
            )
              ? bulkFulfillmentRows
              : undefined
          }
          fulfillmentRecords={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
            )
              ? getFulfillmentRecords
              : undefined
          }
          onPaginationClick={(requestedPageNumber: number) => {
            const config: QuotationAPIConfig = {
              ...QuotationService.apiConfig,
              Page: requestedPageNumber - 1 // Books backend pagination offset is 0, so subtract 1
            };
            setFilterDataInStore(
              { ...config },
              quotesFilterData?.SearchTerm,
              deepClone(quotesFilterData?.queries)
            );
          }}
          onRowSelect={(selectedRows) => {
            const selectedRowIds =
              selectedRows?.map((row: any) => row.quotationCode) || [];
            setBulkFulfillmentRecords(selectedRowIds);
            setSelectedRows(selectedRowIds);
            setCurrentSelectedRows(selectedRows);
            setBulkApprovalRecords(selectedRows);
            setBulkRejectRecords(selectedRows);
            updateBulkPrintRecord(selectedRows);
          }}
          onRowOpenClick={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.QUOTATION.VIEW
            )
              ? onQuoteEdit
              : false
          }
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          onColumnDelete={columnDelete}
          onRowUpdate={rowUpdate}
          allowColumnSort={true}
          onSort={(data: any) => sortQuotes(data)}
          bulkApprovalRows={bulkApprovalRows}
          onBulkApproval={bulkApprovalClick}
          bulkRejectRows={bulkRejectRows}
          onBulkReject={bulkRejectClick}
          onBulkPrint={onBulkPrintlick}
          bulkPrintRows={bulkPrintRecordData}
          needNoDataView={
            !quotesData.content?.length || !quotesDraftData?.length
          }
          noDataTitle={`No ${localizedText('quotes')} found`}
          noDataText={`Create your first ${localizedText('quote')} now`}
          searchBarConfig={{ searchText: searchTerm }}
          permissionModule={PERMISSIONS_BY_MODULE.QUOTATION}
          onRowClick={(data: any) => {}}
        />

        {showPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={`Update ${Utility.convertInTitleCase(
              localizedText('quote')
            )}`}
            btnList={popupBtnConfig}
          >
            <NewQuote
              isCenterAlign={true}
              populateFormData={editableQuote}
              documentMode={DOCUMENT_MODE.EDIT}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
            />
          </PopupWrapper>
        )}

        {showApprovalOrRejectPopup && (
          <ApprovalOrReject
            data={approvalOrRejectData}
            actionData={{
              isBulkAction: false,
              action: onApproveDraft
            }}
            setShowApprovalOrRejectPopup={setShowApprovalOrRejectPopup}
            title={`${approvalOrRejectData.title} Quote`}
          ></ApprovalOrReject>
        )}

        {showDropshipConfirmationPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={localizedText('Fulfill Your Invoice')}
            width={!isDesktop ? '80%' : '460px'}
            height={'auto'}
            overflowVisible={true}
            disableClickOutside={true}
            btnList={fulfillmentConfirmationPopupBtnConfig}
          >
            <DropshipConfirmation
              documentDetails={{ ...editableQuote }}
              fulfillmentType={editableQuote?.fulfillmentType}
              passingInteraction={(
                callback: CallBackPayloadType,
                data: any
              ) => {
                setEditableQuote(data);
                parentChildInteraction(callback);
              }}
              closePopup={() => {
                setShowDropshipConfirmationPopup(false);
              }}
              openFulfillmentPopup={() => {
                setShowDropshipConfirmationPopup(false);
                setShowFulfillmentConfirmationPopup(true);
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}

        {showFulfillmentConfirmationPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={`${localizedText(
              'Fulfill Your'
            )} ${Utility.convertInTitleCase(localizedText('quotation'))}`}
            width={!isDesktop ? '80%' : '460px'}
            height={'auto'}
            overflowVisible={true}
            disableClickOutside={true}
            btnList={fulfillmentConfirmationPopupBtnConfig}
          >
            <FulfillmentConfirmation
              documentDetails={editableQuote}
              fulfillmentType={editableQuote?.fulfillmentType}
              passingInteraction={(
                callback: CallBackPayloadType,
                data: any
              ) => {
                setEditableQuote(data);
                parentChildInteraction(callback);
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}

        {showFulfillmentPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={localizedText('Direct Fulfillment of Quotation')}
            height={'auto'}
            minHeight={'50%'}
            width={'100%'}
            btnList={fulfillmentPopupBtnConfig}
            disableClickOutside={true}
          >
            <Fulfillment
              documentDetails={editableQuote}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              closePopup={() => {
                setShowFulfillmentPopup(false);
              }}
              documentType={DOC_TYPE.QUOTE}
              isFulfillmentForDropship={isFulfillmentForDropship}
            />
          </PopupWrapper>
        )}

        {showBulkFulfillmentPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={t(`QUOTES.BULK_FULFILLMENT_OF_QUOTATION.TITLE`)}
            height={'auto'}
            minHeight={'60%'}
            disableClickOutside={true}
            width={!isDesktop ? '80%' : '65%'}
            btnList={bulkFulfillmentPopupBtnConfig}
          >
            <BulkFulillment
              documentDetails={bulkFulfillmentDetails}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}

        {showFulfillmentRecordsPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={fulfillmentType}
            height={'80%'}
            width={!isDesktop ? '90%' : '65%'}
            btnList={fulfillmentRecordsBtnConfig}
            disableClickOutside={true}
            showScrollBar={true}
          >
            <FulfillmentRecords
              documentDetails={editableQuote}
              fulfillmentDetails={fulfillmentDetails}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              isDeleted={(val: boolean) => {
                if (val) {
                  loadQuotes({});
                }
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}
        {showSalesReturnPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={'Create Sales Return'}
            height={'auto'}
            minHeight={'50%'}
            width={!isDesktop ? '90%' : '100%'}
            disableClickOutside={true}
            btnList={salesReturnPopupBtnConfig}
          >
            <SalesReturnPopup
              documentDetails={editableQuote}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              closePopup={() => {
                setShowSalesReturnPopup(false);
              }}
              isClosed={(val: boolean, data: any) => {
                if (val) {
                  createDocForReturn(data);
                }
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}
        {showSalesReturnRecordsPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={'Sales Returns Records'}
            height={'80%'}
            width={!isDesktop ? '80%' : '60%'}
            btnList={fulfillmentRecordsBtnConfig}
            disableClickOutside={true}
          >
            <SalesReturnRecords
              documentDetails={editableQuote}
              salesReturnsDetails={salesReturnsDetails}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              isDeleted={(val: boolean) => {
                if (val) {
                  loadQuotes({});
                }
              }}
              isClosed={(val: boolean) => {
                if (val) {
                  setShowSalesReturnRecordsPopup(false);
                }
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}
        {showDropShipWarningPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={t(`QUOTES.DROPSHIP.TITLE`)}
            btnList={dropShipButtonConfig}
            disableClickOutside={true}
            width={!isDesktop ? '80%' : '600px'}
            height={'auto'}
          >
            <DropshipWarning
              document={editableQuote}
              passingInteraction={(callback: CallBackPayloadType, data) => {
                parentChildInteraction(callback);
                setEditableQuote(data);
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}

        {showConversionPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={t(`QUOTES.CONVERT_TO_INVOICE.TITLE`)}
            btnList={convertPopupBtnConfig}
            width={!isDesktop ? '80%' : '600px'}
            height={'auto'}
            disableClickOutside={true}
            overflowVisible={true}
          >
            <QuoteToInvoicePopup
              quoteDocument={editableQuote}
              closePopup={() => {
                setShowConversionPopup(false);
                if (!Utility.isEmpty(actionInURL) && showConversionPopup) {
                  history.replace(PAGE_ROUTES.QUOTES);
                }
              }}
            />
          </PopupWrapper>
        )}
        {showSOConversionPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title="Convert to Sales Order"
            btnList={convertPopupBtnConfig}
            width={!isDesktop ? '80%' : '600px'}
            height={'auto'}
            disableClickOutside={true}
            overflowVisible={true}
          >
            <QuoteToSalesOrderPopup
              quoteDocument={editableQuote}
              closePopup={() => {
                setShowSOConversionPopup(false);
              }}
            />
          </PopupWrapper>
        )}
        {showRecurringPopup && (
          <RecurringDocument
            documentDetails={editableQuote}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={DOC_TYPE.QUOTE}
            closePopup={() => setShowRecurringPopup(false)}
          />
        )}
        {showAttachmentPopup && !Utility.isEmpty(editableQuote) && (
          <Attachments
            documentDetails={editableQuote}
            documentType={DOC_TYPE.QUOTE}
            onClose={(docId: any, updatedAttachments: any[]) => {
              const docList: any[] = quotesData['content'];
              const docData = docList.filter((doc) => doc.id === docId)[0];
              let refreshList = false;
              if (
                docData.attachmentsWithLink?.length !==
                updatedAttachments?.length
              ) {
                refreshList = true;
              }
              if (!refreshList && docData) {
                for (let attachment of updatedAttachments) {
                  const existingAttachment = docData.attachmentsWithLink?.find(
                    (att: any) => att.attachmentId === attachment.attachmentId
                  );
                  if (Utility.isEmpty(existingAttachment)) {
                    refreshList = true;
                    break;
                  }
                }
              }
              if (refreshList) {
                loadQuotes({});
              }
              setShowAttachmentPopup(false);
            }}
          />
        )}
        {showExpenseButton && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={'Create Direct Expense'}
            btnList={directExpenseBtnConfig}
            height={'98%'}
            allowFullScreen
          >
            <ExpenseDepositForm
              formType={MODULES_NAME.EXPENSE}
              populateData={expenseData}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              allowFullScreen
            />
          </PopupWrapper>
        )}
        {showCNAndDNInfo.showPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={'Create Credit Note'}
            disableClickOutside={true}
            readOnly={false}
            btnList={CNBtnConfig}
            height={'98%'}
            allowFullScreen
          >
            <CreateAndEditNotes
              documentMode={DOCUMENT_MODE.NEW}
              readOnly={false}
              populateFormData={showCNAndDNInfo.data}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              notesType={showCNAndDNInfo.documentType}
              showLinkedDocPopup={(data: any) => {}}
              allowFullScreen
            />
          </PopupWrapper>
        )}
        {showRateAnalysisForm && rateAnalysisData && (
          <RateAnalysisForm
            data={rateAnalysisData}
            onCancel={() => {
              setShowRateAnalysisForm(false);
            }}
            onSave={() => {
              setShowRateAnalysisForm(false);
            }}
          />
        )}
        {showApprovalHistoryPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={'Approval/Rejection History'}
            height={'80%'}
            width={'60%'}
            btnList={fulfillmentRecordsBtnConfig}
            disableClickOutside={true}
          >
            <ApprovalHistory
              documentDetails={editableQuote}
              approvalHistoryDetails={approvalHistoryData}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              isClosed={(val: boolean) => {
                if (val) {
                  setShowApprovalHistoryPopup(false);
                }
              }}
              documentType={DOC_TYPE.QUOTE}
            />
          </PopupWrapper>
        )}
        {detailsPopupData?.showDetailsOpener && (
          <DetailsOpener
            data={detailsPopupData}
            onCancel={() => {
              setDetailsPopupData({
                showDetailsOpener: false
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export const getDashboardWidgetLoading = () => {
  return (
    <div className="parent-width parent-height row justify-content-center">
      <DKSpinner title="Loading..." />
    </div>
  );
};
