import {
  CESS_RULE_AMOUNT,
  CESS_RULE_QUANTITY,
  CURRENCY_PRECISION,
  EXCISE_FLAT,
  EXCISE_PERCENT,
  GST_TYPE,
  TAX_SYSTEM
} from '../../Constants/Constant';
import { CASCADING_DISCOUNT_TYPE } from '../../Constants/Enum';
import { DocumentItem } from '../../Models/DocumentItem';
import { Store } from '../../Redux/Store';
import Utility from '../../Utility/Utility';
import {
  CASCADING_DISCOUNT_PREFIX,
  getTenantTaxSystem
} from './NewDocumentHelper';
import {
  evaluateTaxGroup,
  evaluateTaxGroupInclusiveTax,
  roundingOff,
  roundOff
} from './NewDocumentHelper';

export class ItemTaxCalculator {
  static tenantInfo: any = Store.getState().authInfo.currentTenantInfo.data;
  static allItems: any[];
  static additionalCharges: any;
  static item: DocumentItem;

  static setInitialValues() {
    if (ItemTaxCalculator.item) {
      ItemTaxCalculator.setSubTotal();
      ItemTaxCalculator.setDiscountAmount();
      ItemTaxCalculator.setCascadingDiscountAmount();
      ItemTaxCalculator.setTotalWithDiscount();
    }
  }

  static updateCalculatedValues() {
    if (ItemTaxCalculator.item) {
      ItemTaxCalculator.setSubTotal();
      ItemTaxCalculator.setDiscountAmount();
      ItemTaxCalculator.setCascadingDiscountAmount();
      ItemTaxCalculator.setTotalWithDiscount();
      ItemTaxCalculator.setTotal();
    }
  }

  static isGlobalDiscountBeforeTax() {
    const globalDiscount = ItemTaxCalculator?.additionalCharges?.globalDiscount;
    if (!Utility.isEmpty(globalDiscount)) {
      return globalDiscount?.applyOnBeforeTax;
    }
    return false;
  }

  static setSubTotal() {
    const price = ItemTaxCalculator.item?.unitPrice
      ? Utility.roundOff(ItemTaxCalculator.item.unitPrice, CURRENCY_PRECISION)
      : 0;
    const quantity = ItemTaxCalculator.item?.productQuantity
      ? ItemTaxCalculator.item.productQuantity
      : 0;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      ItemTaxCalculator.item.cessPercentage =
        ItemTaxCalculator.getCessPercentage(price);
      const amountBeforeDiscount = price * quantity;
      let itemBaseAmount = 0;
      if (
        ItemTaxCalculator.item.unitPriceGstInclusive &&
        ItemTaxCalculator.item.tax?.percent
      ) {
        itemBaseAmount =
          amountBeforeDiscount /
          (1 +
            ((ItemTaxCalculator.item.gstType !== GST_TYPE.EXEMPT &&
            ItemTaxCalculator.item.tax &&
            ItemTaxCalculator.item.tax.percent
              ? ItemTaxCalculator.item.tax.percent
              : 0) +
              (ItemTaxCalculator.item.cessPercentage || 0)) /
              100);
      } else {
        itemBaseAmount = amountBeforeDiscount;
      }

      ItemTaxCalculator.item.subTotal = itemBaseAmount;
    } else {
      let calprice =
        ItemTaxCalculator.item.unitPriceGstInclusive &&
        ItemTaxCalculator.item.tax?.percent
          ? price /
            (1 +
              (ItemTaxCalculator.item.tax && ItemTaxCalculator.item.tax.percent
                ? ItemTaxCalculator.item.tax.percent
                : 0) /
                100)
          : price;
      ItemTaxCalculator.item.subTotal = calprice * quantity;
    }
  }

  static getSubTotal(item: any) {
    const price = item?.unitPrice
      ? Utility.roundOff(item.unitPrice, CURRENCY_PRECISION)
      : 0;
    const quantity = item?.productQuantity ? item.productQuantity : 0;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      item.cessPercentage = ItemTaxCalculator.getCessPercentage(price);
      const amountBeforeDiscount = price * quantity;
      let itemBaseAmount = 0;
      if (item.unitPriceGstInclusive && item.tax?.percent) {
        itemBaseAmount =
          amountBeforeDiscount /
          (1 +
            ((item.gstType !== GST_TYPE.EXEMPT && item.tax && item.tax.percent
              ? item.tax.percent
              : 0) +
              (item.cessPercentage || 0)) /
              100);
      } else {
        itemBaseAmount = amountBeforeDiscount;
      }

      item.subTotal = itemBaseAmount;
    } else {
      let calprice =
        item.unitPriceGstInclusive && item.tax?.percent
          ? price /
            (1 + (item.tax && item.tax.percent ? item.tax.percent : 0) / 100)
          : price;
      item.subTotal = calprice * quantity;
    }

    return item.subTotal;
  }

  static getPreTaxGlobalDiscount() {
    let preTaxGlobalDiscount = 0;
    ItemTaxCalculator.item.preTaxGlobalDiscount = 0;
    if (ItemTaxCalculator.isGlobalDiscountBeforeTax()) {
      const beforeTaxTotalAmount = ItemTaxCalculator.allItems?.reduce(
        (sum: number, item: any) => {
          if (item?.lineNumber === ItemTaxCalculator?.item?.lineNumber) {
            const subTotal = ItemTaxCalculator.getSubTotal({
              ...ItemTaxCalculator?.item
            });
            return (
              sum +
              (subTotal || 0) -
              (ItemTaxCalculator?.item?.discountAmount || 0) -
              (ItemTaxCalculator?.item?.cascadingDiscountAmount || 0)
            );
          } else {
            const subTotal = ItemTaxCalculator.getSubTotal({ ...item });
            return (
              sum +
              (subTotal || 0) -
              (item?.discountAmount || 0) -
              (item?.cascadingDiscountAmount || 0)
            );
          }
        },
        0
      );
      const beforeTaxItemAmount =
        (ItemTaxCalculator.item?.subTotal || 0) -
        (ItemTaxCalculator.item?.discountAmount || 0) -
        (ItemTaxCalculator.item?.cascadingDiscountAmount || 0);
      const globalDiscountAmount = isNaN(
        +ItemTaxCalculator?.additionalCharges?.globalDiscount?.amount
      )
        ? 0
        : +ItemTaxCalculator?.additionalCharges?.globalDiscount?.amount;
      const globalDiscountShare =
        beforeTaxTotalAmount !== 0
          ? beforeTaxItemAmount / beforeTaxTotalAmount
          : 0;
      preTaxGlobalDiscount = Utility.roundOff(
        globalDiscountShare * globalDiscountAmount,
        CURRENCY_PRECISION
      );
    }
    return preTaxGlobalDiscount;
  }

  static setTotalWithDiscount() {
    if (
      typeof ItemTaxCalculator.item.exciseAmount === 'undefined' ||
      ItemTaxCalculator.item.exciseAmount === null
    ) {
      ItemTaxCalculator.item.exciseAmount = 0;
    }

    ItemTaxCalculator.item.preTaxGlobalDiscount =
      ItemTaxCalculator.getPreTaxGlobalDiscount();

    ItemTaxCalculator.item.totalWithDiscount =
      typeof ItemTaxCalculator.item.subTotal === 'number' &&
      typeof ItemTaxCalculator.item.exciseAmount === 'number' &&
      typeof ItemTaxCalculator.item.discountAmount === 'number' &&
      typeof ItemTaxCalculator.item.cascadingDiscountAmount === 'number'
        ? ItemTaxCalculator.item.subTotal +
          ItemTaxCalculator.item.exciseAmount -
          ItemTaxCalculator.item.discountAmount -
          ItemTaxCalculator.item.cascadingDiscountAmount -
          ItemTaxCalculator.item.preTaxGlobalDiscount
        : 0;
    ItemTaxCalculator.item.totalWithDiscount = Utility.roundOff(
      ItemTaxCalculator.item.totalWithDiscount,
      CURRENCY_PRECISION
    );
  }

  static setTotal() {
    ItemTaxCalculator.item.total = ItemTaxCalculator.item.isRcmApplied
      ? (ItemTaxCalculator.item.totalWithDiscount as number) +
        Number(
          ItemTaxCalculator.item.otherTaxAmount
            ? ItemTaxCalculator.item.otherTaxAmount
            : 0
        )
      : (ItemTaxCalculator.item.totalWithDiscount as number) +
        Number(
          ItemTaxCalculator.item.taxAmount
            ? ItemTaxCalculator.item.taxAmount
            : 0
        );
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      ItemTaxCalculator.item.unitPriceGstInclusive &&
      ItemTaxCalculator.item.taxSystem === TAX_SYSTEM.INDIA_GST
    ) {
      if (ItemTaxCalculator.item.gstType === GST_TYPE.INTRA) {
        //CGST SGST Calculation
        let totalAfterDiscount =
          Utility.roundOff(
            ItemTaxCalculator.item.subTotal || 0,
            CURRENCY_PRECISION
          ) -
          (ItemTaxCalculator.item.discountAmount
            ? ItemTaxCalculator.item.discountAmount
            : 0) -
          (ItemTaxCalculator.item.cascadingDiscountAmount
            ? ItemTaxCalculator.item.cascadingDiscountAmount
            : 0) -
          (ItemTaxCalculator.item.preTaxGlobalDiscount
            ? ItemTaxCalculator.item.preTaxGlobalDiscount
            : 0);

        let otherTotalTax = Utility.roundOff(
          totalAfterDiscount *
            (ItemTaxCalculator.item.tax?.otherRate
              ? ItemTaxCalculator.item.tax?.otherRate
              : 0 / 100),
          CURRENCY_PRECISION
        );

        otherTotalTax = Utility.roundOff(otherTotalTax, CURRENCY_PRECISION);

        let taxAmount = 0;
        if (!ItemTaxCalculator.item.isRcmApplied) {
          taxAmount =
            Utility.roundOff(
              ItemTaxCalculator.item.cgstAmount
                ? ItemTaxCalculator.item.cgstAmount
                : 0,
              CURRENCY_PRECISION
            ) +
            Utility.roundingOff(
              ItemTaxCalculator.item.sgstAmount
                ? ItemTaxCalculator.item.sgstAmount
                : 0,
              CURRENCY_PRECISION
            );
        }

        ItemTaxCalculator.item.totalAmount =
          Utility.roundOff(totalAfterDiscount, CURRENCY_PRECISION) +
          Utility.roundOff(taxAmount, CURRENCY_PRECISION) +
          otherTotalTax +
          (!ItemTaxCalculator.item.isRcmApplied &&
          ItemTaxCalculator.item.cessAmount
            ? ItemTaxCalculator.item.cessAmount
            : 0);
      } else if (ItemTaxCalculator.item.gstType === GST_TYPE.INTER) {
        let totalAfterDiscount =
          Utility.roundOff(
            ItemTaxCalculator.item.subTotal || 0,
            CURRENCY_PRECISION
          ) -
          (ItemTaxCalculator.item.discountAmount
            ? ItemTaxCalculator.item.discountAmount
            : 0) -
          (ItemTaxCalculator.item.cascadingDiscountAmount
            ? ItemTaxCalculator.item.cascadingDiscountAmount
            : 0) -
          (ItemTaxCalculator.item.preTaxGlobalDiscount
            ? ItemTaxCalculator.item.preTaxGlobalDiscount
            : 0);

        let otherTotalTax = Utility.roundOff(
          totalAfterDiscount *
            (ItemTaxCalculator.item.tax?.otherRate
              ? ItemTaxCalculator.item.tax?.otherRate
              : 0 / 100),
          CURRENCY_PRECISION
        );

        otherTotalTax = Utility.roundOff(otherTotalTax, CURRENCY_PRECISION);

        let taxAmount = 0;
        if (!ItemTaxCalculator.item.isRcmApplied) {
          taxAmount = Utility.roundingOff(
            ItemTaxCalculator.item.igstAmount
              ? ItemTaxCalculator.item.igstAmount
              : 0,
            CURRENCY_PRECISION
          );
        }

        ItemTaxCalculator.item.totalAmount =
          Utility.roundOff(totalAfterDiscount, CURRENCY_PRECISION) +
          Utility.roundOff(taxAmount, CURRENCY_PRECISION) +
          otherTotalTax +
          (!ItemTaxCalculator.item.isRcmApplied &&
          ItemTaxCalculator.item.cessAmount
            ? ItemTaxCalculator.item.cessAmount
            : 0);
      } else if (ItemTaxCalculator.item.gstType === GST_TYPE.EXEMPT) {
        let totalAfterDiscount =
          Utility.roundOff(
            ItemTaxCalculator.item.subTotal || 0,
            CURRENCY_PRECISION
          ) -
          (ItemTaxCalculator.item.discountAmount
            ? ItemTaxCalculator.item.discountAmount
            : 0) -
          (ItemTaxCalculator.item.cascadingDiscountAmount
            ? ItemTaxCalculator.item.cascadingDiscountAmount
            : 0) -
          (ItemTaxCalculator.item.preTaxGlobalDiscount
            ? ItemTaxCalculator.item.preTaxGlobalDiscount
            : 0);
        let otherTotalTax = Utility.roundOff(
          totalAfterDiscount *
            (ItemTaxCalculator.item.tax?.otherRate
              ? ItemTaxCalculator.item.tax?.otherRate
              : 0 / 100),
          CURRENCY_PRECISION
        );

        otherTotalTax = Utility.roundOff(otherTotalTax, CURRENCY_PRECISION);
        ItemTaxCalculator.item.totalAmount =
          Utility.roundOff(totalAfterDiscount, CURRENCY_PRECISION) +
          otherTotalTax +
          (!ItemTaxCalculator.item.isRcmApplied &&
          ItemTaxCalculator.item.cessAmount
            ? ItemTaxCalculator.item.cessAmount
            : 0);
      }
    } else {
      ItemTaxCalculator.item.totalAmount = Utility.roundOff(
        ItemTaxCalculator.item.total,
        CURRENCY_PRECISION
      );
      //   ItemTaxCalculator.item.totalAmount = ItemTaxCalculator.item.total;
    }
  }

  static setDiscountAmount() {
    ItemTaxCalculator.item.totalDiscountAmount = 0;
    if (
      typeof ItemTaxCalculator.item.discount !== 'undefined' &&
      ItemTaxCalculator.item.discount !== null
    ) {
      if (ItemTaxCalculator.item.discountInPercent) {
        if (ItemTaxCalculator.item.unitPriceGstInclusive) {
          let baseAmount = Utility.roundOff(
            ItemTaxCalculator.item.subTotal
              ? ItemTaxCalculator.item.subTotal
              : 0,
            CURRENCY_PRECISION
          );

          if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
            ItemTaxCalculator.item.discountAmount = Utility.roundOff(
              Utility.roundOff(baseAmount, CURRENCY_PRECISION) *
                (ItemTaxCalculator.item.discount / 100),
              CURRENCY_PRECISION
            );
          } else {
            ItemTaxCalculator.item.discountAmount = Utility.roundOff(
              Utility.roundOff(
                ItemTaxCalculator.item.subTotal
                  ? ItemTaxCalculator.item.subTotal
                  : 0,
                CURRENCY_PRECISION
              ) *
                (ItemTaxCalculator.item.discount / 100),
              CURRENCY_PRECISION
            );
          }
        } else {
          ItemTaxCalculator.item.discountAmount = Utility.roundOff(
            ((ItemTaxCalculator.item.subTotal as number) *
              Number(ItemTaxCalculator.item.discount)) /
              100,
            CURRENCY_PRECISION
          );
        }
      } else {
        ItemTaxCalculator.item.discountAmount = Number(
          ItemTaxCalculator.item.discount
        );
      }
    } else {
      ItemTaxCalculator.item.discountAmount = 0;
    }
    ItemTaxCalculator.item.totalDiscountAmount +=
      ItemTaxCalculator.item.discountAmount;
  }

  static setCascadingDiscountAmount() {
    ItemTaxCalculator.item.totalDiscountAmount = ItemTaxCalculator.item
      .totalDiscountAmount
      ? ItemTaxCalculator.item.totalDiscountAmount
      : 0;
    const cascadingDiscountSettings =
      ItemTaxCalculator.tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    ItemTaxCalculator.item.cascadingDiscountAmount = 0;
    if (cascadingDiscountSettings?.enable) {
      const price = ItemTaxCalculator.item.unitPrice
        ? ItemTaxCalculator.item.unitPrice
        : 0;
      const quantity = ItemTaxCalculator.item.productQuantity
        ? ItemTaxCalculator.item.productQuantity
        : 0;
      let unitSubTotal = 0;
      const itemDiscountMethod = ItemTaxCalculator.item?.itemDiscountMethod;
      const discountDetailsKeys = Object.keys(ItemTaxCalculator.item)?.filter(
        (key: string) =>
          key.startsWith(CASCADING_DISCOUNT_PREFIX) && key.endsWith('_details')
      );
      let discountDetails: any[] = [];
      discountDetailsKeys.forEach((discKey: string) => {
        const lineItem = ItemTaxCalculator.item as any;
        if (!Utility.isEmpty(lineItem[discKey])) {
          discountDetails.push(lineItem[discKey]);
        }
      });
      discountDetails?.sort(
        (discount1: any, discount2: any) =>
          discount1.discountIndex - discount2.discountIndex
      );

      if (!discountDetails.length) {
        ItemTaxCalculator.item.cascadingDiscountAmount = 0;
      } else {
        if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
          ItemTaxCalculator.item.cessPercentage =
            ItemTaxCalculator.getCessPercentage(price);
          if (
            ItemTaxCalculator.item.unitPriceGstInclusive &&
            ItemTaxCalculator.item.tax?.percent
          ) {
            unitSubTotal =
              price /
              (1 +
                ((ItemTaxCalculator.item.gstType !== GST_TYPE.EXEMPT &&
                ItemTaxCalculator.item.tax &&
                ItemTaxCalculator.item.tax.percent
                  ? ItemTaxCalculator.item.tax.percent
                  : 0) +
                  (ItemTaxCalculator.item.cessPercentage || 0)) /
                  100);
          } else {
            unitSubTotal = price;
          }
        } else {
          unitSubTotal =
            ItemTaxCalculator.item.unitPriceGstInclusive &&
            ItemTaxCalculator.item.tax?.percent
              ? price /
                (1 +
                  (ItemTaxCalculator.item.tax &&
                  ItemTaxCalculator.item.tax.percent
                    ? ItemTaxCalculator.item.tax.percent
                    : 0) /
                    100)
              : price;
        }
        let unitDiscount = 0;
        let totalDiscount = 0;
        if (itemDiscountMethod === CASCADING_DISCOUNT_TYPE.SUBTOTAL) {
          discountDetails.forEach((disc: any) => {
            if (disc.isPercent) {
              unitDiscount = (unitSubTotal * +disc.discount) / 100;
              unitDiscount = Utility.roundOff(unitDiscount, CURRENCY_PRECISION);
            } else {
              unitDiscount = +disc.discount;
            }
            const keyToUpdate = `${CASCADING_DISCOUNT_PREFIX}${disc.id}_details`;
            (ItemTaxCalculator.item as any)[keyToUpdate] = {
              ...(ItemTaxCalculator.item as any)[keyToUpdate],
              unitDiscount: unitDiscount
            };
            totalDiscount += Utility.roundOff(
              unitDiscount * quantity,
              CURRENCY_PRECISION
            );
          });
          ItemTaxCalculator.item.cascadingDiscountAmount = totalDiscount;
        } else if (itemDiscountMethod === CASCADING_DISCOUNT_TYPE.CASCADE) {
          let currentAmount = unitSubTotal;
          discountDetails.forEach((disc: any) => {
            if (disc.isPercent) {
              unitDiscount = (currentAmount * +disc.discount) / 100;
              unitDiscount = Utility.roundOff(unitDiscount, CURRENCY_PRECISION);
            } else {
              unitDiscount = +disc.discount;
            }
            const keyToUpdate = `${CASCADING_DISCOUNT_PREFIX}${disc.id}_details`;
            (ItemTaxCalculator.item as any)[keyToUpdate] = {
              ...(ItemTaxCalculator.item as any)[keyToUpdate],
              unitDiscount: unitDiscount
            };
            totalDiscount += Utility.roundOff(
              unitDiscount * quantity,
              CURRENCY_PRECISION
            );
            currentAmount -= unitDiscount;
          });
          ItemTaxCalculator.item.cascadingDiscountAmount = totalDiscount;
        }
      }
      ItemTaxCalculator.item.totalDiscountAmount +=
        ItemTaxCalculator.item.cascadingDiscountAmount;
    }
  }

  static getCessPercentage(unitPrice: number) {
    const cessAmount = ItemTaxCalculator.calculateCessAmount(unitPrice);
    return (cessAmount / unitPrice) * 100;
  }

  static getItemTaxPercent() {
    return ItemTaxCalculator.item.tax && ItemTaxCalculator.item.tax.percent
      ? ItemTaxCalculator.item.tax.percent
      : 0;
  }

  static calculateExciseAmount() {
    if (
      ItemTaxCalculator.item.exciseRate &&
      ItemTaxCalculator.item.exciseType
    ) {
      switch (ItemTaxCalculator.item.exciseType) {
        case EXCISE_PERCENT:
          ItemTaxCalculator.item.exciseAmount =
            typeof ItemTaxCalculator.item.subTotal === 'number'
              ? Utility.roundOff(
                  (ItemTaxCalculator.item.subTotal *
                    ItemTaxCalculator.item.exciseRate) /
                    100,
                  CURRENCY_PRECISION
                )
              : 0;
          break;
        case EXCISE_FLAT:
          let quantity = ItemTaxCalculator.item.productQuantity
            ? ItemTaxCalculator.item.productQuantity
            : 0;
          ItemTaxCalculator.item.exciseAmount = Utility.roundOff(
            quantity * ItemTaxCalculator.item.exciseRate,
            CURRENCY_PRECISION
          );
          break;
      }
    }
  }

  // Main method for triggering tax calculation
  static calculateTaxAmount = () => {
    let tax: number = 0;
    switch (ItemTaxCalculator.item.taxSystem) {
      case TAX_SYSTEM.INDIA_GST:
        tax = ItemTaxCalculator.calculateIndiaTax() as number;
        break;
      case TAX_SYSTEM.SG:
      case TAX_SYSTEM.AUSTRALIA:
      case TAX_SYSTEM.NZ:
      case TAX_SYSTEM.NL:
      case TAX_SYSTEM.BE:
      case TAX_SYSTEM.CANADA:
      case TAX_SYSTEM.UK:
      case TAX_SYSTEM.DE:
        tax = ItemTaxCalculator.calculateTaxGroup();
        break;
      default:
        const calculateTax =
          (typeof ItemTaxCalculator.item.taxPreference === 'boolean' &&
            ItemTaxCalculator.item.taxPreference) ||
          typeof ItemTaxCalculator.item.taxPreference === 'undefined' ||
          ItemTaxCalculator.item.taxPreference === null;
        if (calculateTax) {
          tax =
            ItemTaxCalculator.item.tax &&
            ItemTaxCalculator.item.tax.percent !== undefined
              ? ItemTaxCalculator.getTaxInPercent(
                  ItemTaxCalculator.item.tax.percent
                )
              : ItemTaxCalculator.item.taxAmount
              ? ItemTaxCalculator.item.taxAmount
              : 0;
        } else {
          tax = 0;
        }

        break;
    }
    return tax;
  };

  static getTaxInPercent(percent: number) {
    // Commenting this to revert to old calculation

    // if (ItemTaxCalculator.item.unitPriceGstInclusive) {
    //   if (ItemTaxCalculator.item.unitPrice) {
    //     return percent
    //       ? roundOff(
    //           ItemTaxCalculator.item.unitPrice -
    //             ItemTaxCalculator.item.unitPrice / (1 + percent / 100)
    //         )
    //       : 0;
    //   }
    // } else
    if (ItemTaxCalculator.item.totalWithDiscount) {
      return percent
        ? Utility.roundOff(
            (ItemTaxCalculator.item.totalWithDiscount * percent) / 100,
            CURRENCY_PRECISION
          )
        : 0;
    }
    return 0;
  }

  static getTaxInPercentForCalculation(percent: number) {
    return percent
      ? Utility.roundOff(
          (ItemTaxCalculator.item.totalWithDiscount
            ? ItemTaxCalculator.item.totalWithDiscount * percent
            : 0 * percent) / 100,
          CURRENCY_PRECISION
        )
      : 0;
  }

  // Calculate Indian tax
  static calculateIndiaTax() {
    ItemTaxCalculator.item.cgstRate = 0;
    ItemTaxCalculator.item.sgstRate = 0;
    ItemTaxCalculator.item.igstRate = 0;
    ItemTaxCalculator.item.otherTaxAmount = 0;
    ItemTaxCalculator.item.otherRate = 0;
    ItemTaxCalculator.item.rcmRate = 0;
    ItemTaxCalculator.item.taxDetails = [];
    if (ItemTaxCalculator.item.tax && !ItemTaxCalculator.item.tax.isTaxGroup) {
      ItemTaxCalculator.item.rcmRate =
        ItemTaxCalculator.item.tax && ItemTaxCalculator.item.tax.percent
          ? ItemTaxCalculator.item.tax.percent
          : 0;
      switch (ItemTaxCalculator.item.gstType) {
        case GST_TYPE.INTER:
          ItemTaxCalculator.item.igstRate =
            ItemTaxCalculator.item.compositionTaxPercent &&
            ItemTaxCalculator.item.compositionTaxPercent > 0
              ? ItemTaxCalculator.item.compositionTaxPercent
              : ItemTaxCalculator.item.tax && ItemTaxCalculator.item.tax.percent
              ? ItemTaxCalculator.item.tax.percent
              : 0;
          ItemTaxCalculator.item.rcmRate = ItemTaxCalculator.item.igstRate;
          break;
        case GST_TYPE.INTRA:
          const halfGstRate =
            (ItemTaxCalculator.item.compositionTaxPercent &&
            ItemTaxCalculator.item.compositionTaxPercent > 0
              ? ItemTaxCalculator.item.compositionTaxPercent
              : ItemTaxCalculator.item.tax && ItemTaxCalculator.item.tax.percent
              ? ItemTaxCalculator.item.tax.percent
              : 0) / 2;
          ItemTaxCalculator.item.cgstRate = halfGstRate;
          ItemTaxCalculator.item.sgstRate = halfGstRate;
          ItemTaxCalculator.item.rcmRate =
            ItemTaxCalculator.item.cgstRate + ItemTaxCalculator.item.sgstRate;
          break;
        case GST_TYPE.EXEMPT:
          ItemTaxCalculator.item.cgstRate = 0;
          ItemTaxCalculator.item.sgstRate = 0;
          ItemTaxCalculator.item.igstRate = 0;
          break;
      }
      //   ItemTaxCalculator.item.cgstAmount =
      //     ItemTaxCalculator.getTaxInPercentForCalculation(
      //       ItemTaxCalculator.item.cgstRate
      //     );
      //   ItemTaxCalculator.item.sgstAmount =
      //     ItemTaxCalculator.getTaxInPercentForCalculation(
      //       ItemTaxCalculator.item.sgstRate
      //     );
      if (ItemTaxCalculator.item.userSetTaxes) {
        ItemTaxCalculator.item.igstAmount =
          ItemTaxCalculator.item.igstAmount || 0;
      } else {
        ItemTaxCalculator.item.igstAmount = ItemTaxCalculator.item.igstRate
          ? ItemTaxCalculator.getTaxInPercentForCalculation(
              ItemTaxCalculator.item.igstRate
            )
          : 0;
      }

      if (ItemTaxCalculator.item.unitPriceGstInclusive) {
        let totalAfterDiscount =
          Utility.roundOff(
            ItemTaxCalculator.item.subTotal || 0,
            CURRENCY_PRECISION
          ) -
          (ItemTaxCalculator.item.discountAmount
            ? ItemTaxCalculator.item.discountAmount
            : 0) -
          (ItemTaxCalculator.item.cascadingDiscountAmount
            ? ItemTaxCalculator.item.cascadingDiscountAmount
            : 0) -
          (ItemTaxCalculator.item.preTaxGlobalDiscount
            ? ItemTaxCalculator.item.preTaxGlobalDiscount
            : 0);

        let totalGSTTax = 0;

        if (ItemTaxCalculator.item.userSetTaxes) {
          if (ItemTaxCalculator.item?.gstType === GST_TYPE.INTER) {
            totalGSTTax = ItemTaxCalculator.item.igstAmount || 0;
          }
          if (ItemTaxCalculator.item?.gstType === GST_TYPE.INTRA) {
            totalGSTTax =
              (ItemTaxCalculator.item?.cgstAmount || 0) +
              (ItemTaxCalculator.item?.sgstAmount || 0);
          }
        } else {
          totalGSTTax = Utility.roundOff(
            (totalAfterDiscount *
              (ItemTaxCalculator.item?.gstType !== GST_TYPE.EXEMPT &&
              ItemTaxCalculator.item?.tax?.percent
                ? ItemTaxCalculator.item?.tax?.percent
                : 0)) /
              100,
            CURRENCY_PRECISION
          );
        }

        ItemTaxCalculator.item.cgstAmount =
          ItemTaxCalculator.item.cgstRate && ItemTaxCalculator.item.cgstRate > 0
            ? Utility.roundOff(totalGSTTax / 2, CURRENCY_PRECISION)
            : 0;
        ItemTaxCalculator.item.sgstAmount = ItemTaxCalculator.item.cgstAmount;

        ItemTaxCalculator.item.igstAmount =
          ItemTaxCalculator.item.igstRate && ItemTaxCalculator.item.igstRate > 0
            ? Utility.roundOff(totalGSTTax, CURRENCY_PRECISION)
            : 0;
        ItemTaxCalculator.item.cessAmount = !ItemTaxCalculator.item.userSetTaxes
          ? ItemTaxCalculator.calculateCessAmount()
          : ItemTaxCalculator.item.cessAmount;
      } else {
        ItemTaxCalculator.item.cgstAmount = 0;
        if (
          ItemTaxCalculator.item.cgstRate &&
          ItemTaxCalculator.item.cgstRate > 0
        ) {
          const tempTaxValueFromPercent = ItemTaxCalculator.item?.tax?.percent
            ? ItemTaxCalculator.item?.tax?.percent / 100
            : 0;
          const cgstAmount =
            ((ItemTaxCalculator.item.totalWithDiscount
              ? ItemTaxCalculator.item.totalWithDiscount
              : 0) *
              tempTaxValueFromPercent) /
            2;
          ItemTaxCalculator.item.cgstAmount = Utility.roundOff(
            cgstAmount,
            CURRENCY_PRECISION
          );
        }

        ItemTaxCalculator.item.sgstAmount = ItemTaxCalculator.item.cgstAmount;

        ItemTaxCalculator.item.cessAmount = !ItemTaxCalculator.item.userSetTaxes
          ? ItemTaxCalculator.calculateCessAmount()
          : ItemTaxCalculator.item.cessAmount;
      }

      ItemTaxCalculator.item.cgstAmount = Utility.roundOff(
        ItemTaxCalculator.item.cgstAmount,
        CURRENCY_PRECISION
      );
      ItemTaxCalculator.item.sgstAmount = Utility.roundOff(
        ItemTaxCalculator.item.sgstAmount,
        CURRENCY_PRECISION
      );
      ItemTaxCalculator.item.otherTaxAmount = Utility.roundOff(
        ItemTaxCalculator.item.otherTaxAmount,
        CURRENCY_PRECISION
      );
      ItemTaxCalculator.item.igstAmount = Utility.roundOff(
        ItemTaxCalculator.item.igstAmount,
        CURRENCY_PRECISION
      );
      ItemTaxCalculator.item.cessAmount &&
        (ItemTaxCalculator.item.cessAmount = Utility.roundOff(
          ItemTaxCalculator.item.cessAmount,
          CURRENCY_PRECISION
        ));
      return (
        ItemTaxCalculator.item.cgstAmount +
        ItemTaxCalculator.item.sgstAmount +
        (ItemTaxCalculator.item.igstAmount || 0) +
        (ItemTaxCalculator.item.cessAmount
          ? ItemTaxCalculator.item.cessAmount
          : 0)
      );
    } else if (
      ItemTaxCalculator.item.tax &&
      ItemTaxCalculator.item.tax.isTaxGroup
    ) {
      let tax: number = 0;
      let exemptAmount: number = 0;
      tax = ItemTaxCalculator.calculateTaxGroup();
      exemptAmount = ItemTaxCalculator.calculateGSTTax();
      tax = Number(tax) - Number(exemptAmount);
      ItemTaxCalculator.item.cessAmount = !ItemTaxCalculator.item.userSetTaxes
        ? ItemTaxCalculator.item.isRcmApplied
          ? null
          : ItemTaxCalculator.calculateCessAmount()
        : ItemTaxCalculator.item.cessAmount;
      tax += ItemTaxCalculator.item.cessAmount as number;
      return tax;
    }
  }

  static calculateCessAmount(totalAfterDiscount?: number) {
    let cessAmount = 0;
    const taxableAmount =
      typeof totalAfterDiscount === 'number'
        ? totalAfterDiscount
        : ItemTaxCalculator.item.totalWithDiscount;
    if (ItemTaxCalculator.item.cessRule && taxableAmount) {
      let cessExpression = '';
      if (ItemTaxCalculator.item.productQuantity) {
        cessExpression = ItemTaxCalculator.item.cessRule.replace(
          CESS_RULE_QUANTITY,
          `${ItemTaxCalculator.item.productQuantity}`
        );
      }

      if (taxableAmount) {
        cessExpression = cessExpression
          ? cessExpression.replace(CESS_RULE_AMOUNT, `${taxableAmount}`)
          : ItemTaxCalculator.item.cessRule.replace(
              CESS_RULE_AMOUNT,
              `${taxableAmount}`
            );
      }
      if (cessExpression) {
        cessAmount = eval(cessExpression);
      }
    }
    return Utility.roundOff(cessAmount, CURRENCY_PRECISION);
  }

  static calculateTaxGroup() {
    let taxDetails;
    if (!ItemTaxCalculator.item.unitPriceGstInclusive) {
      taxDetails = evaluateTaxGroup(
        ItemTaxCalculator.item.tax,
        ItemTaxCalculator.item.totalWithDiscount as number
      );
    } else {
      const totalAmount =
        (ItemTaxCalculator.item.unitPrice
          ? ItemTaxCalculator.item.unitPrice
          : 0) *
        (ItemTaxCalculator.item.productQuantity
          ? ItemTaxCalculator.item.productQuantity
          : 0);
      const totalTax = Utility.roundOff(
        totalAmount -
          (typeof ItemTaxCalculator.item.subTotal === 'number'
            ? ItemTaxCalculator.item.subTotal
            : 0),
        CURRENCY_PRECISION
      );
      taxDetails = evaluateTaxGroupInclusiveTax(
        ItemTaxCalculator.item.tax,
        ItemTaxCalculator.item.totalWithDiscount as number,
        totalTax
      );
    }
    ItemTaxCalculator.item.taxDetails = taxDetails;

    let tax = 0;
    taxDetails.forEach((taxDetail: any) => (tax += taxDetail.taxAmount));
    return tax;
  }

  static calculateGSTTax() {
    ItemTaxCalculator.item.cgstAmount = 0;
    ItemTaxCalculator.item.sgstAmount = 0;
    ItemTaxCalculator.item.igstAmount = 0;
    ItemTaxCalculator.item.otherTaxAmount = 0;
    let exemptAmount = 0;
    ItemTaxCalculator.item.tax?.taxGroupDetails?.forEach((element: any) => {
      if (!element.additionalTaxIn) {
        switch (ItemTaxCalculator.item.gstType) {
          case GST_TYPE.INTER:
            ItemTaxCalculator.item.igstRate =
              ItemTaxCalculator.item.compositionTaxPercent &&
              ItemTaxCalculator.item.compositionTaxPercent > 0
                ? ItemTaxCalculator.item.compositionTaxPercent
                : element.percentage;
            if (
              typeof ItemTaxCalculator.item.rcmRate === 'number' &&
              typeof ItemTaxCalculator.item.igstRate === 'number'
            ) {
              ItemTaxCalculator.item.rcmRate += ItemTaxCalculator.item.igstRate;
            }
            break;
          case GST_TYPE.INTRA:
            const halfGstRate =
              (ItemTaxCalculator.item.compositionTaxPercent &&
              ItemTaxCalculator.item.compositionTaxPercent > 0
                ? ItemTaxCalculator.item.compositionTaxPercent
                : element.percentage) / 2;
            if (
              typeof ItemTaxCalculator.item.cgstRate === 'number' &&
              typeof ItemTaxCalculator.item.sgstRate === 'number' &&
              typeof ItemTaxCalculator.item.rcmRate === 'number'
            ) {
              ItemTaxCalculator.item.cgstRate += halfGstRate;
              ItemTaxCalculator.item.sgstRate += halfGstRate;
              ItemTaxCalculator.item.rcmRate +=
                ItemTaxCalculator.item.cgstRate +
                ItemTaxCalculator.item.sgstRate;
            } else {
              ItemTaxCalculator.item.cgstRate = 0;
              ItemTaxCalculator.item.sgstRate = 0;
              ItemTaxCalculator.item.rcmRate = 0;
            }
            break;
          default:
            ItemTaxCalculator.item.rcmRate += element.percentage;
        }
      } else {
        ItemTaxCalculator.item.otherRate += element.percentage;
      }
    });

    ItemTaxCalculator.item.taxDetails.forEach((taxDetail: any) => {
      if (!taxDetail.additionalTaxIn) {
        switch (ItemTaxCalculator.item.gstType) {
          case GST_TYPE.INTER:
            ItemTaxCalculator.item.igstAmount += taxDetail.taxAmount;

            break;
          case GST_TYPE.INTRA:
            const halfGstAmount = taxDetail.taxAmount / 2;
            if (
              typeof ItemTaxCalculator.item.cgstAmount === 'number' &&
              typeof ItemTaxCalculator.item.sgstAmount === 'number'
            ) {
              ItemTaxCalculator.item.cgstAmount += halfGstAmount;
              ItemTaxCalculator.item.sgstAmount += halfGstAmount;
            }
            break;
          case GST_TYPE.EXEMPT:
            exemptAmount += taxDetail.taxAmount;
            taxDetail.taxAmount = 0;
            break;
        }
      } else {
        ItemTaxCalculator.item.otherTaxAmount += taxDetail.taxAmount;
      }
    });

    ItemTaxCalculator.item.cgstAmount = Utility.roundOff(
      ItemTaxCalculator.item.cgstAmount,
      CURRENCY_PRECISION
    );
    ItemTaxCalculator.item.sgstAmount = Utility.roundOff(
      ItemTaxCalculator.item.sgstAmount,
      CURRENCY_PRECISION
    );
    ItemTaxCalculator.item.igstAmount = Utility.roundOff(
      ItemTaxCalculator.item.igstAmount,
      CURRENCY_PRECISION
    );
    ItemTaxCalculator.item.otherTaxAmount = Utility.roundOff(
      ItemTaxCalculator.item.otherTaxAmount,
      CURRENCY_PRECISION
    );

    return exemptAmount;
  }
}
