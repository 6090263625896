import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import {
  CURRENCY_CONSTANTS,
  CURRENCY_EXCHANGE_CONSTANTS,
  CURRENCY_HISTORY_CONSTANTS,
  STATUS_TYPE
} from '../Constants/Constant';

export interface CurrencyAPIConfig {
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  Dir?: string;
  Start?: number;
  Search?: string;
  Query?: string;
}

export interface RealtimeCurrencyAPIConfig {
  startDate: string;
  endDate: string;
  baseCurrency: string;
  primaryCurrency: string;
}

const defaultConfig: CurrencyAPIConfig = {
  Limit: 1000,
  Page: 0,
  SortDir: CURRENCY_EXCHANGE_CONSTANTS.SortDir,
  Sort: CURRENCY_EXCHANGE_CONSTANTS.Sort,
  Dir: CURRENCY_EXCHANGE_CONSTANTS.Dir,
  Start: 0,
  Search: '',
  Query: ''
};

const currencyListConfig: CurrencyAPIConfig = {
  Limit: 1000,
  Page: 0,
  SortDir: CURRENCY_CONSTANTS.SortDir,
  Sort: CURRENCY_CONSTANTS.Sort,
  Dir: CURRENCY_CONSTANTS.Dir,
  Start: 0,
  Query: ''
};

const currencyHistoryConfig: CurrencyAPIConfig = {
  Limit: 1000,
  Page: 0,
  SortDir: CURRENCY_HISTORY_CONSTANTS.SortDir,
  Sort: CURRENCY_HISTORY_CONSTANTS.Sort,
  Dir: CURRENCY_HISTORY_CONSTANTS.Dir,
  Start: 0,
  Query: ''
};
class CurrencyService {
  static apiConfig: CurrencyAPIConfig;
  static currencyListApiConfig: CurrencyAPIConfig;
  static currencyHistoryApiConfig: CurrencyAPIConfig;

  static realtimeCurrencyHistory: RealtimeCurrencyAPIConfig;

  static getCurrencyList() {
    if (Utility.isEmpty(this.currencyListApiConfig)) {
      this.currencyListApiConfig = currencyListConfig;
    }
    const queryString: string = `?limit=${this.currencyListApiConfig.Limit}&page=${this.currencyListApiConfig.Page}&sortDir=${this.currencyListApiConfig.SortDir}&sort=${this.currencyListApiConfig.Sort}&start=${this.currencyListApiConfig.Start}&dir=${this.currencyListApiConfig.Dir}`;
    const finalEndpoint: string =
      ApiConstants.URL.CURRENCY.FETCH_CURRENCY + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading currency: ', err);
      return Promise.reject(err);
    });
  }

  static getCurrencyListWithExchangeRates() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&sortDir=${this.apiConfig.SortDir}&sort=${this.apiConfig.Sort}&start=${this.apiConfig.Start}&dir=${this.apiConfig.Dir}&search=${this.apiConfig.Search}`;
    const finalEndpoint: string =
      ApiConstants.URL.CURRENCY.FETCH_CURRENCY_WITH_EXCHANGE_RATE + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading currency exchange rates: ', err);
      return Promise.reject(err);
    });
  }

  static getCurrencyListWithExchangeRatesBydocDate(docDate: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&sortDir=${this.apiConfig.SortDir}&sort=${this.apiConfig.Sort}&start=${this.apiConfig.Start}&dir=${this.apiConfig.Dir}&search=${this.apiConfig.Search}`;
    if (!Utility.isEmpty(docDate)) {
      queryString += `&documentDate=${docDate}`;
    }
    const finalEndpoint: string =
      ApiConstants.URL.CURRENCY.FETCH_CURRENCY_WITH_EXCHANGE_RATE + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading currency exchange rates by doc date: ', err);
      return Promise.reject(err);
    });
  }

  static updateCurrencySyncConfig(payload: any) {
    return http
      .put(`${ApiConstants.URL.CURRENCY.UPDATE_CURRENCY_SYNC_CONFIG}`, payload)
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static updateCurrencyStatus(payload: any) {
    const finalEndPoint = ApiConstants.URL.CURRENCY.UPDATE_CURRENCY_STATUS;
    return http
      .put(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateBulkCurrencyStatus(payload: any) {
    const finalEndPoint = ApiConstants.URL.CURRENCY.UPDATE_BULK_STATUS;
    return http
      .put(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getCurrencySyncConfig() {
    const finalEndpoint: string =
      ApiConstants.URL.CURRENCY.GET_CURRENCY_SYNC_CONFIG;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading currency sync data: ', err);
      return Promise.reject(err);
    });
  }

  static getCurrencyHistrory() {
    if (Utility.isEmpty(this.currencyHistoryApiConfig)) {
      this.currencyHistoryApiConfig = currencyHistoryConfig;
    }
    const queryString: string = `?limit=${this.currencyHistoryApiConfig.Limit}&page=${this.apiConfig.Page}&sortDir=${this.currencyHistoryApiConfig.SortDir}&sort=${this.currencyHistoryApiConfig.Sort}&query=${this.currencyHistoryApiConfig.Query}&start=${this.currencyHistoryApiConfig.Start}&dir=${this.apiConfig.Dir}`;
    const finalEndpoint: string =
      ApiConstants.URL.CURRENCY.GET_CURRENCY_HISTORY + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading currency exchange rates: ', err);
      return Promise.reject(err);
    });
  }

  static getRealtimeCurrencyHistory(payload: RealtimeCurrencyAPIConfig) {
    const finalEndpoint: string =
      ApiConstants.URL.JOURNAL_ENTRY.REALTIME_EXCHANGE_RATE;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error loading currency exchange rates: ', err);
      return Promise.reject(err);
    });
  }
  static updateExchangeRate(payload: any) {
    const finalEndPoint = ApiConstants.URL.CURRENCY.UPDATE_EXCHANGE_RATE;
    return http
      .put(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default CurrencyService;
