import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import { saveAs } from 'file-saver';

export interface PickPackShipAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  Query?: string;
  StartDate?: string;
  EndDate?: string;
}

const defaultConfig: PickPackShipAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: 'DESC',
  Sort: '',
  Query: ''
};

class PickPackShipService {
  static apiConfig: PickPackShipAPIConfig = defaultConfig;

  static getAllPickPackData(config: PickPackShipAPIConfig) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    } else {
      this.apiConfig = config;
    }
    const searchString = this.apiConfig.SearchTerm
      ? encodeURIComponent(this.apiConfig.SearchTerm)
      : '';
    const query: string = `?search=${searchString}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&query=${this.apiConfig.Query}&sort=${
      this.apiConfig.Sort ? this.apiConfig.Sort : 'documentDate'
    }&sortDir=${this.apiConfig.SortDir}&fromDate=${
      this.apiConfig.StartDate
    }&toDate=${this.apiConfig.EndDate}`;
    const finalEndPoint = ApiConstants.URL.PICK_PACK_SHIP.ALL + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getPickingList(config: PickPackShipAPIConfig) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    } else {
      this.apiConfig = config;
    }
    const searchString = this.apiConfig.SearchTerm
      ? encodeURIComponent(this.apiConfig.SearchTerm)
      : '';
    const query: string = `?search=${searchString}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&query=${this.apiConfig.Query}&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
    const finalEndPoint = ApiConstants.URL.PICK_PACK_SHIP.PICKING_LIST + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getShippingList(config: PickPackShipAPIConfig) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    } else {
      this.apiConfig = config;
    }
    const searchString = this.apiConfig.SearchTerm
      ? encodeURIComponent(this.apiConfig.SearchTerm)
      : '';
    const query: string = `?search=${searchString}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&query=${this.apiConfig.Query}&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}&fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}`;
    const finalEndPoint = ApiConstants.URL.PICK_PACK_SHIP.SHIPING_LIST + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGeneralPickPackList(payload: any) {
    const finalEndPoint =
      ApiConstants.URL.PICK_PACK_SHIP.GENERAL_PICK_PACK_LIST;
    return http
      .post(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static postGeneralPickPackList(payload: any) {
    const finalEndPoint =
      ApiConstants.URL.PICK_PACK_SHIP.POST_GENERAL_PICK_PACK_LIST;
    return http
      .post(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getPackListDetail(documentId: string, documentType: string) {
    let url =
      ApiConstants.URL.PICK_PACK_SHIP.PACK_DETAILS +
      `?search=${documentId}&query=documentType=${documentType}`;
    return http
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getPickList(code: string) {
    let url = ApiConstants.URL.PICK_PACK_SHIP.FETCH_PICKLIST(
      `query=code=${code}`
    );
    return http
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updatePickList(code: string, payload: any) {
    let url = ApiConstants.URL.PICK_PACK_SHIP.UPDATE_PICKLIST(code);
    return http
      .put(url, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static savePackDocument(documentObject: any) {
    let url = 'products/inventories/pack-list';
    return http
      .post(url, documentObject)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getShipList(code: string, type: string) {
    let url = `${ApiConstants.URL.PICK_PACK_SHIP.FETCH_SHIPPING_LIST}/${code}/${type}`;
    return http
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updatePackDocument(documentObject: any) {
    let url = 'products/inventories/pack-list';
    return http
      .put(url, documentObject)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateShipList(code: string, payload: any, type: string) {
    let url = `${ApiConstants.URL.PICK_PACK_SHIP.FETCH_SHIPPING_LIST}/${code}/${type}`;
    return http
      .put(url, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static voidList(query: any, payload: any, documentCode?: any) {
    let url = ApiConstants.URL.PICK_PACK_SHIP.VOID_SHIPPING_LIST(query);
    if (documentCode) {
      url = url + `/${documentCode}`;
    }
    return http
      .put(url, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static performAction(status: any, payload: any) {
    const url = `${ApiConstants.URL.PICK_PACK_SHIP.GO_ACTION}?status=${status}`;
    return http.patch(url, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static printLabels(payload: any) {
    let url = `${ApiConstants.URL.PICK_PACK_SHIP.PRINT_LABELS}`;
    return http
      .post(url, payload, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, `ShippingLabel-${payload[0].documentCode}.pdf`);
        },
        (error: any) => {}
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getInvoiceShipList(code: string, documentType: string) {
    let url = `${ApiConstants.URL.PICK_PACK_SHIP.INVOICE_SHIP_LIST(
      code,
      documentType
    )}`;
    return http
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static checkPPSDocumentPresent() {
    let url = `${ApiConstants.URL.PICK_PACK_SHIP.CHECK_PPS_DOCUMENT}`;
    return http
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default PickPackShipService;
